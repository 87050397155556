import { di } from 'react-magnetic-di';

export const getShareUrl = (path: string, search: string) => {
	di(window);
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const { hostname, protocol } = window.location;
	return `${protocol}//${hostname}${path}${search}${
		search !== '' && search !== undefined ? '&' : '?'
	}shared`;
};
