import type { MouseEvent, KeyboardEvent } from 'react';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

import type { ListItemMenuOptionProps } from '@atlassian/timeline-table/renderers';
import { getBulkEditDatesOptionParams } from '../../../../../state/selectors/list-item-menu-option/index.tsx';
import type { State } from '../../../../../state/types.tsx';
import BulkEditDatesOption from './view.tsx';

type OwnProps = {
	id: IssueId;
	setOpenModal: (event: MouseEvent | KeyboardEvent) => void;
	Option: ListItemMenuOptionProps['Option'];
};

type StateProps = {
	isDateEditable: boolean;
};

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => {
	const { isDateEditable } = getBulkEditDatesOptionParams(state, id);

	return {
		isDateEditable,
	};
};

export default connect(mapStateToProps)(BulkEditDatesOption);
