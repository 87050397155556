import { createSelector } from 'react-sweet-state';

import { fg } from '@atlaskit/platform-feature-flags';

import { LAST } from '../../../constants';
import type { ItemId } from '../../../types/item';
import { getItemLevel } from '../../../utils/hierarchy-enriched-items';
import type { State } from '../types';

export const getIsTableEmpty = (state: State) => state.isTableEmpty;
export const getIsItemSelectedOld = (
	{ itemsSelectionState }: State,
	id: ItemId,
): boolean | undefined => (itemsSelectionState ? itemsSelectionState[id] ?? false : undefined);

export const getIsItemMultiSelectedOld = (
	{ itemsSelectionState, itemsSelectionCount }: State,
	id: ItemId,
): boolean | undefined => {
	if (!itemsSelectionState || itemsSelectionCount === undefined) {
		return undefined;
	}

	return (itemsSelectionCount > 1 && itemsSelectionState[id]) || false;
};

export const getItemsSelectionCount = (state: State) => state.selectedItemIds.length;
export const getItems = (state: State) => state.items;
export const getItemIndexes = (state: State) => state.itemIndexes;
export const getItemsContainerStyles = (state: State) => ({
	itemsBackground: state.itemsBackground,
	totalHeight: state.totalHeight,
});
export const getItemHeight = (state: State) => state.itemHeight;
export const getItemsCount = (state: State) => state.items.length;
export const getAriaRowCount = (state: State) => state.ariaRowCount;
export const getHasActiveCreate = (state: State): boolean => state.createItemAnchor !== undefined;

export const getCreateItemAnchor = (state: State) => state.createItemAnchor;
export const getCreateItemAnchorIndex = ({ createItemAnchor, itemIndexes }: State) => {
	if (!createItemAnchor || createItemAnchor.position === LAST) {
		return undefined;
	}

	if (createItemAnchor.beforeId) {
		return itemIndexes[createItemAnchor.beforeId];
	}
	if (createItemAnchor.parentId) {
		return itemIndexes[createItemAnchor.parentId];
	}
};

export const getSelectedItemIds = (state: State) => state.selectedItemIds;
export const getActiveItemId = (state: State) => state.activeItemId;

export const getIsItemActive = (state: State, id: ItemId): boolean => state.activeItemId === id;

export const getSelectedItemSet = createSelector(
	[getSelectedItemIds],
	(selectedItemIds) => new Set(selectedItemIds),
);

export const getIsItemSelected = (state: State, id: ItemId): boolean | undefined => {
	if (!fg('project_timeline_multi-select_and_checkboxes')) {
		return getIsItemSelectedOld(state, id);
	}
	return getSelectedItemSet(state).has(id);
};

// Remove `undefined` with project_timeline_multi-select_and_checkboxes
export const getIsItemMultiSelected = (state: State, id: ItemId): boolean | undefined => {
	if (!fg('project_timeline_multi-select_and_checkboxes')) {
		return getIsItemMultiSelectedOld(state, id);
	}
	const selection = getSelectedItemSet(state);
	return selection.size > 1 && selection.has(id);
};

const getFirstSelectedItem = ({ items, itemIndexes, selectedItemIds }: State) =>
	selectedItemIds.length > 0 ? items[itemIndexes[selectedItemIds[0]]] : undefined;

export const getIsItemSelectable = createSelector(
	[getItems, getItemIndexes, getFirstSelectedItem, (_: State, itemId: string) => itemId],
	(items, itemIndexes, firstSelectedItem, itemId) => {
		if (!firstSelectedItem) {
			return true;
		}

		const item = items[itemIndexes[itemId]];

		if (!item) {
			return false;
		}

		return (
			getItemLevel(firstSelectedItem) === getItemLevel(item) &&
			firstSelectedItem.meta.parentId === item.meta.parentId
		);
	},
);
