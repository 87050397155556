import type { StyleAPI } from './style-marshal';
import type {
	CustomActions,
	DefaultActions,
	OverrideMarshalOptions,
	PublicStyleAPI,
} from './types';

/* We only want to expose the primitive style utils for consumers.
 * Everything else is an internal abstraction, which should remain private.
 */
const getPublicStyleAPI = (styleAPI: StyleAPI): PublicStyleAPI => ({
	insertStyle: styleAPI.insertStyle,
	removeStyle: styleAPI.removeStyle,
	updateStyle: styleAPI.updateStyle,
});

/* Takes the custom actions (optionally) provided by the table consumer and forwards
 * them the necessary APIs to perform operations using the side effect marshal.
 *
 * E.g. myCustomAction() becomes myCustomAction({ styleAPI })
 */
export const bindCustomActions = (customActions: CustomActions, styleAPI: StyleAPI) =>
	Object.keys(customActions).reduce((acc: CustomActions, key) => {
		acc[key] = customActions[key].bind(null, { styleAPI: getPublicStyleAPI(styleAPI) });
		return acc;
	}, {});

/* Takes two action functions and combines them into a single function.
 * The aforementioned actions will be called in order, with the parameters of the first.
 *
 * Getting stricter generic types was impractical, but we ultimately still have good type
 * safety due to the calling function being strictly typed.
 */
const combineActions =
	<
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		A1 extends (...args: any[]) => void,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		A2 extends (api: { styleAPI: PublicStyleAPI }, ...args: any[]) => void,
	>(
		actionOne: A1,
		actionTwo: A2 | undefined,
		styleAPI: StyleAPI,
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(...args: any[]) => {
		actionOne(...args);
		actionTwo?.bind(null, { styleAPI: getPublicStyleAPI(styleAPI) })(...args);
	};

/* Takes the override actions (optionally) provided by the table consumer and combines
 * them with the default actions of the same name. The override action will be passed
 * the necessary APIs to perform operations using the side effect marshal.
 *
 * E.g. onRowMouseEnter becomes...
 * (Call 1) onRowMouseEnter(id)
 * (Call 2) onRowMouseEnter(id, { styleAPI })
 */
export const bindOverrideActions = (
	defaultActions: DefaultActions,
	overrideActions: Partial<OverrideMarshalOptions>,
	styleAPI: StyleAPI,
) =>
	Object.keys(defaultActions).reduce((acc, key) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const defaults = defaultActions[key as keyof DefaultActions];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const overrides = overrideActions[key as keyof OverrideMarshalOptions];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		acc[key as keyof DefaultActions] = combineActions(defaults, overrides, styleAPI);
		return acc;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, {} as DefaultActions);
