import kebabCase from 'lodash/kebabCase';
import { metrics } from '@atlassian/browser-metrics';
import {
	LIST_DRAG_AND_DROP,
	LIST_RESIZE_DRAG_AND_DROP,
	CHILD_LIST_DRAG_AND_DROP,
	SCROLLED,
} from '@atlassian/timeline-table/common/constants';
import {
	DEPENDENCY_DRAG_AND_DROP,
	ISSUE_VIEW_RESIZE_DRAG_AND_DROP,
	CHART_BAR_RESIZE,
	PERSIST_ITEM,
	EXPORT_IMAGE_GENERATED,
	ISSUE_UPDATED,
	DEPENDENCY_ADDED,
	DEPENDENCY_REMOVED,
	BULK_ISSUES_SCHEDULED,
	RESOLVE_HEALTHCHECK,
	CRITICAL_DATA,
	VERSION_DETAILS,
	SPRINT_DETAILS,
	FILTER_CONFIGURATION,
	DERIVE_FIELDS,
	FILTER_ROADMAP_ITEMS,
	GET_ISSUE_IDS,
	GET_ITEMS_BY_IDS,
	GET_SUBTASKS,
	LOAD_HEALTHCHECK_ISSUE_TYPES,
	JQL_FILTERS_REAPPLY,
	GET_GOALS,
	type InteractionMetricType,
	type MutationMetricType,
	type QueryMetricType,
} from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import interactionSLOs from '../metrics/interaction-slos.tsx';

const STATE_CHANGE_BASED_ISSUE_HIDDEN_FLAGS = 'jsw-roadmap-state-change-based-issue-hidden-flags';

const buildInteractionParameters = (
	type: InteractionMetricType | MutationMetricType | QueryMetricType,
	featureFlags?: string[],
) => {
	const slo = interactionSLOs[type] || 0;
	return {
		key: `roadmap.${kebabCase(type)}`,
		featureFlags,
		...(slo
			? {
					slo: {
						response: { threshold: slo },
						result: { threshold: slo },
					},
				}
			: undefined),
	};
};

const getInteractionMetrics = (
	type: InteractionMetricType | MutationMetricType | QueryMetricType,
	featureFlags?: string[],
) => metrics.interaction(buildInteractionParameters(type, featureFlags));

const getConcurrentInteractionMetrics = (
	type: InteractionMetricType | MutationMetricType | QueryMetricType,
) => metrics.concurrent.interaction(buildInteractionParameters(type));

export const interactionMetrics = {
	[LIST_DRAG_AND_DROP]: getInteractionMetrics(LIST_DRAG_AND_DROP),
	[CHILD_LIST_DRAG_AND_DROP]: getInteractionMetrics(CHILD_LIST_DRAG_AND_DROP),
	[LIST_RESIZE_DRAG_AND_DROP]: getInteractionMetrics(LIST_RESIZE_DRAG_AND_DROP),
	[DEPENDENCY_DRAG_AND_DROP]: getInteractionMetrics(DEPENDENCY_DRAG_AND_DROP),
	[CHART_BAR_RESIZE]: getInteractionMetrics(CHART_BAR_RESIZE),
	[EXPORT_IMAGE_GENERATED]: getInteractionMetrics(EXPORT_IMAGE_GENERATED),
	[SCROLLED]: getInteractionMetrics(SCROLLED),
	[JQL_FILTERS_REAPPLY]: getInteractionMetrics(JQL_FILTERS_REAPPLY, [
		STATE_CHANGE_BASED_ISSUE_HIDDEN_FLAGS,
	]),
	[ISSUE_VIEW_RESIZE_DRAG_AND_DROP]: getInteractionMetrics(ISSUE_VIEW_RESIZE_DRAG_AND_DROP),
} as const;

export const mutationMetrics = {
	[PERSIST_ITEM]: getConcurrentInteractionMetrics(PERSIST_ITEM),
	[DEPENDENCY_ADDED]: getConcurrentInteractionMetrics(DEPENDENCY_ADDED),
	[DEPENDENCY_REMOVED]: getConcurrentInteractionMetrics(DEPENDENCY_REMOVED),
	[BULK_ISSUES_SCHEDULED]: getConcurrentInteractionMetrics(BULK_ISSUES_SCHEDULED),
	[ISSUE_UPDATED]: getConcurrentInteractionMetrics(ISSUE_UPDATED),
	[RESOLVE_HEALTHCHECK]: getConcurrentInteractionMetrics(RESOLVE_HEALTHCHECK),
} as const;

export const queryMetrics = {
	[CRITICAL_DATA]: getConcurrentInteractionMetrics(CRITICAL_DATA),
	[FILTER_CONFIGURATION]: getConcurrentInteractionMetrics(FILTER_CONFIGURATION),
	[DERIVE_FIELDS]: getConcurrentInteractionMetrics(DERIVE_FIELDS),
	[VERSION_DETAILS]: getConcurrentInteractionMetrics(VERSION_DETAILS),
	[SPRINT_DETAILS]: getConcurrentInteractionMetrics(SPRINT_DETAILS),
	[FILTER_ROADMAP_ITEMS]: getConcurrentInteractionMetrics(FILTER_ROADMAP_ITEMS),
	[GET_ISSUE_IDS]: getConcurrentInteractionMetrics(GET_ISSUE_IDS),
	[GET_ITEMS_BY_IDS]: getConcurrentInteractionMetrics(GET_ITEMS_BY_IDS),
	[GET_SUBTASKS]: getConcurrentInteractionMetrics(GET_SUBTASKS),
	[LOAD_HEALTHCHECK_ISSUE_TYPES]: getConcurrentInteractionMetrics(LOAD_HEALTHCHECK_ISSUE_TYPES),
	[GET_GOALS]: getConcurrentInteractionMetrics(GET_GOALS),
} as const;
