import type { VersionId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CreationPreferences } from '@atlassian/jira-software-roadmap-model/src/settings/index.tsx';

import type { TimelineMode } from '@atlassian/timeline-table/common/types/timeline';
import type { State } from '../types.tsx';

export const getStoredTimelineFilter = (state: State): TimelineMode | undefined =>
	state.settings.timelineMode;

export const getListWidth = (state: State): number => state.settings.listWidth;

export const getCreationPreferences = (state: State): CreationPreferences =>
	state.settings.creationPreferences;

export const getHighlightedVersionsInSettings = (state: State): VersionId[] =>
	state.settings.highlightedVersions;

export const getIssuePanelRatio = (state: State): number => state.settings.issuePanelRatio;
