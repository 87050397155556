import { createSelector } from 'reselect';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueType as FilterIssueType } from '@atlassian/jira-filters/src/common/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Hash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import {
	RELEASES_HEADER_ID,
	SPRINTS_HEADER_ID,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	DEFAULT_ITEM_HEIGHT,
	DEFAULT_SUBHEADER_HEIGHT,
} from '@atlassian/timeline-table/common/constants';
import type { HeaderItem } from '@atlassian/timeline-table/common/types/header';
import type { Item } from '@atlassian/timeline-table/common/types/item';
import {
	hasRequiredFields,
	getFullIssueTypeHash,
	getEpicIssueTypeIds,
	getProjectDefaultItemTypeId,
	getIsSprintsPlanningEnabled,
	getIsSprintsFeatureEnabled,
	getIsChildIssuePlanningEnabled,
} from '../../configuration/selectors.tsx';
import {
	getIssueIds,
	getIssueColorHash,
	getIssueCreationTransitionHash,
	getIssueDueDateHash,
	getIssueParentIdHash,
	getIssueStartDateHash,
	getIssueStatusHash,
	getIssueAssigneeHash,
	getIsIssueFlaggedHash,
} from '../../entities/issues/selectors.tsx';
import { getDoneStatusCategoryId } from '../../entities/status-categories/selectors.tsx';
import { getUserAvatar16x16Hash, getUserDisplayNameHash } from '../../entities/users/selectors.tsx';
import { getCreationPreferences } from '../../settings/selectors.tsx';
import type { State } from '../../types.tsx';
import { getExpandedItems, getCreateItemAnchor } from '../../ui/table/selectors.tsx';
import { getIssuesFilter, isProgressVisible, getChildIssueTypes } from '../filters/index.tsx';
import { getFilteredNonBaseLevelIssueIds, getFilteredChildIssueIdsHash } from '../issues/index.tsx';
import { getItemsHeight } from '../items.tsx';
import { getCanUserCreate, getCanUserRank, getCanUserEdit } from '../permission.tsx';
import { getSanitisedIssueSprintsHash } from '../sprint/index.tsx';
import { getIsReleasesVisible } from '../versions/index.tsx';
import { getIssueDateErrorsHashWithDefaultEqual } from './bar-warnings/index.tsx';
import type { ChartData } from './chart-pure/common/types.tsx';
import { getChartDataHash as getChartDataHashPure } from './chart-pure/index.tsx';
import { getParentRolledUpDatesHash } from './dates/index.tsx';
import {
	getFilteredCreateCountPure,
	getItemTypesByLevelPure,
	getSecondaryFieldsHashPure,
} from './pure/index.tsx';

export const getFilteredCreateCount = createSelector(getIssuesFilter, getFilteredCreateCountPure);

export const getItemTypesByLevel = createSelector(
	getFullIssueTypeHash,
	getEpicIssueTypeIds,
	getCreationPreferences,
	getProjectDefaultItemTypeId,
	getItemTypesByLevelPure,
);

export const getSecondaryFieldsHash = createSelector(
	getIssueIds,
	getIssueParentIdHash,
	getIssueStatusHash,
	getIssueAssigneeHash,
	getUserAvatar16x16Hash,
	getUserDisplayNameHash,
	getDoneStatusCategoryId,
	getIsIssueFlaggedHash,
	getSecondaryFieldsHashPure,
);

export const getChartDataHash = createSelector(
	getFilteredNonBaseLevelIssueIds,
	getFilteredChildIssueIdsHash,
	getIssueStartDateHash,
	getIssueDueDateHash,
	getSanitisedIssueSprintsHash,
	getIssueColorHash,
	getIssueDateErrorsHashWithDefaultEqual,
	getParentRolledUpDatesHash,
	getIssueParentIdHash,
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
	getChartDataHashPure,
);

export const getChartDataById = (state: State, id: IssueId): ChartData | undefined =>
	getChartDataHash(state)[id];

export const getItems = createSelector(
	getFilteredNonBaseLevelIssueIds,
	getFilteredChildIssueIdsHash,
	getIssueCreationTransitionHash,
	(
		nonBaseLevelIssueIds: IssueId[],
		issueChildIdsHash: Hash<IssueId[]>,
		issueCreationTransitionHash: Hash<boolean | undefined>,
	) => {
		const items: Item[] = [];
		const rootIssues = nonBaseLevelIssueIds;

		rootIssues.forEach((issueId) => {
			const childIds = issueChildIdsHash[issueId];

			items.push({
				id: issueId,
				level: 1,
				isInTransition: Boolean(issueCreationTransitionHash[issueId]),
				childItems:
					childIds &&
					childIds.map((childId) => ({
						id: childId,
						level: 0,
						isInTransition: Boolean(issueCreationTransitionHash[childId]),
						canCreateChildOverride: false,
					})),
				canCreateChildOverride: true,
			});
		});

		return items;
	},
);

export const getTableHeaderItems = createSelector(
	getIsSprintsFeatureEnabled,
	getIsReleasesVisible,
	(isSprintsFeatureEnabled: boolean, isReleasesHeaderVisible) => {
		const headerItemConfigs: Array<HeaderItem> = [];
		if (isSprintsFeatureEnabled) {
			headerItemConfigs.push({
				id: SPRINTS_HEADER_ID,
				height: DEFAULT_SUBHEADER_HEIGHT,
			});
		}
		if (isReleasesHeaderVisible) {
			headerItemConfigs.push({
				id: RELEASES_HEADER_ID,
				height: DEFAULT_SUBHEADER_HEIGHT,
			});
		}
		return headerItemConfigs;
	},
);

export const getTableHeaderItemsHeight = createSelector(
	getTableHeaderItems,
	(headerItems: HeaderItem[]) =>
		headerItems.reduce(
			(acc: number, { height }: HeaderItem) => acc + (height ?? DEFAULT_SUBHEADER_HEIGHT),
			0,
		),
);

const PROGRESS_ITEM_HEIGHT_OLD = 44;
const PROGRESS_ITEM_HEIGHT = 40;
export const getItemHeight = createSelector(isProgressVisible, (isVisible) =>
	// eslint-disable-next-line no-nested-ternary
	isVisible
		? isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2')
			? PROGRESS_ITEM_HEIGHT
			: PROGRESS_ITEM_HEIGHT_OLD
		: DEFAULT_ITEM_HEIGHT,
);

/**
 * @deprecated - Get table height from useHeaderState and useItems hook
 * TODO: Remove the usage in getShareTableProps
 */
export const getTableHeight = createSelector(
	getTableHeaderItemsHeight,
	getItemHeight,
	getFilteredNonBaseLevelIssueIds,
	getFilteredChildIssueIdsHash,
	getExpandedItems,
	getCreateItemAnchor,
	getItemsHeight,
);

export const getIsCreateSiblingEnabled = createSelector(
	getCanUserCreate,
	getCanUserRank,
	getCanUserEdit,
	hasRequiredFields,
	(canCreate: boolean, canRank: boolean, canEdit: boolean, hasRequiredField: boolean) =>
		!hasRequiredField && canCreate && canRank && canEdit,
);

export const getIsCreateChildEnabled = createSelector(
	getCanUserCreate,
	getChildIssueTypes,
	(canCreate: boolean, childItemTypes: FilterIssueType[]) => canCreate && childItemTypes.length > 0,
);

export const getIsDragEnabled = createSelector(
	getCanUserRank,
	getCanUserEdit,
	(canRank: boolean, canEdit: boolean) => canRank && canEdit,
);

export const getAriaReadOnly = createSelector(
	getCanUserCreate,
	getCanUserRank,
	getCanUserEdit,
	(canCreate, canRank, canEdit) => !canRank && !canCreate && !canEdit,
);
