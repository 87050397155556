import { createSelector } from 'reselect';
import type {
	Label,
	CustomFilterId as CustomFilterTypeId,
} from '@atlassian/jira-filters/src/common/types.tsx';
import type {
	ComponentId,
	IssueTypeId,
	AccountId,
	VersionId,
	IssueStatusCategoryId,
	IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { Filter } from '@atlassian/jira-software-roadmap-model/src/filter/index.tsx';
import type { QuickFilterId } from '@atlassian/jira-software-roadmap-model/src/quick-filter/index.tsx';

import { WEEKS, MONTHS, QUARTERS } from '@atlassian/timeline-table/common/constants';
import { createSelectorWithDefaultEqual } from '../../../common/utils/reselect/index.tsx';
import { getFullIssueTypeHash, getEpicIssueTypeIds } from '../../configuration/selectors.tsx';
import { getGoalsHash, getUniqueGoalIds } from '../../entities/goals/selectors.tsx';
import { getIssueParentIds, getIssueChildrenHash } from '../../entities/issues/selectors.tsx';
import {
	getQuery,
	getStatus,
	getAssignee,
	getIssueType,
	getLabels,
	getIssueParents,
	getVersions,
	getComponents,
	getGoalAris,
	getQuickFilters,
	getCustomFilters,
	isDependenciesVisibleInQuery,
	isProgressVisibleInQuery,
	isWarningsVisibleInQuery,
	getEpicSettingInQuery,
} from '../../router/selectors.tsx';
import { getStoredTimelineFilter } from '../../settings/selectors.tsx';
import type { State } from '../../types.tsx';
import {
	isDependenciesVisibleInView,
	isProgressVisibleInView,
	isWarningsVisibleInView,
	getLevelOneSettingInView,
} from '../../ui/views/selectors.tsx';
import { getUniqueComponents } from '../components/index.tsx';
import { getLabelsWithUnassignedOption as getUniqueLabels } from '../header/filters/label/selectors.tsx';
import { getUniqueVersions } from '../versions/index.tsx';
import {
	createGetFilteredVersions as createGetFilteredVersionsPure,
	createGetFilteredComponents as createGetFilteredComponentsPure,
	createGetFilteredLabels as createGetFilteredLabelsPure,
	createGetFilteredIssueParents as createGetFilteredIssueParentsPure,
	createGetFilteredGoals as createGetFilteredGoalsPure,
	createGetFilteredGoalIssueIds as createGetFilteredGoalIssueIdsPure,
	getChildIssueTypesPure,
	getLevelOneSettingPure,
} from './pure/index.tsx';

export const getFilteredStatusCategories: (state: State) => IssueStatusCategoryId[] =
	createSelectorWithDefaultEqual(getStatus, (statuses) => statuses);

export const getFilteredAssignees: (state: State) => AccountId[] = createSelectorWithDefaultEqual(
	getAssignee,
	(assignees) => assignees,
);

export const getFilteredIssueTypes: (state: State) => IssueTypeId[] =
	createSelectorWithDefaultEqual(getIssueType, (issueTypes) => issueTypes);

export const getFilteredText = createSelector(getQuery, (query) => query.text ?? '');

export const getFilteredLabels: (state: State) => Label[] = createSelectorWithDefaultEqual(
	getLabels,
	getUniqueLabels,
	createGetFilteredLabelsPure,
);

export const getFilteredIssueParents: (state: State) => IssueId[] = createSelectorWithDefaultEqual(
	getIssueParents,
	getIssueParentIds,
	createGetFilteredIssueParentsPure,
);

export const getFilteredVersions: (state: State) => VersionId[] = createSelectorWithDefaultEqual(
	getVersions,
	getUniqueVersions,
	createGetFilteredVersionsPure,
);

export const getFilteredComponents: (state: State) => ComponentId[] =
	createSelectorWithDefaultEqual(
		getComponents,
		getUniqueComponents,
		createGetFilteredComponentsPure,
	);

export const getFilteredQuickFilters: (state: State) => QuickFilterId[] =
	createSelectorWithDefaultEqual(getQuickFilters, (quickFilters) => quickFilters);

export const getFilteredCustomFilters: (state: State) => CustomFilterTypeId[] =
	createSelectorWithDefaultEqual(getCustomFilters, (customFilters) => customFilters);

export const getNumJQLFiltersApplied: (state: State) => number = createSelectorWithDefaultEqual(
	getQuickFilters,
	getCustomFilters,
	(quickFilters, customFilters) => Math.max(quickFilters.length, customFilters.length),
);

export const getFilteredGoals: (state: State) => string[] = createSelectorWithDefaultEqual(
	getGoalAris,
	getUniqueGoalIds,
	createGetFilteredGoalsPure,
);

export const getFilteredGoalIssueIds: (state: State) => IssueId[] = createSelector(
	getFilteredGoals,
	getGoalsHash,
	getIssueChildrenHash,
	createGetFilteredGoalIssueIdsPure,
);

export const getIssuesFilter = createSelector(
	getFilteredStatusCategories,
	getFilteredAssignees,
	getFilteredIssueTypes,
	getFilteredText,
	getFilteredLabels,
	getFilteredIssueParents,
	getFilteredVersions,
	getFilteredComponents,
	getFilteredQuickFilters,
	getFilteredCustomFilters,
	getFilteredGoals,
	(
		statuses,
		assignees,
		issueTypes,
		text,
		labels,
		issueParents,
		versions,
		components,
		quickFilters,
		customFilters,
		goals,
	) => ({
		statuses,
		assignees,
		issueTypes,
		text,
		labels,
		issueParents,
		versions,
		components,
		quickFilters,
		customFilters,
		goals,
	}),
);

export const isFilterActive = createSelector(getIssuesFilter, (filters: Filter) =>
	Object.values(filters).some((value) => {
		if (Array.isArray(value) || typeof value === 'string') {
			return value.length > 0;
		}
		// should never reach here
		return false;
	}),
);

export const getTimelineMode = createSelector(
	getQuery,
	getStoredTimelineFilter,
	(query, storedTimelineFilter) => {
		switch (query.timeline) {
			case WEEKS:
				return WEEKS;
			case MONTHS:
				return MONTHS;
			case QUARTERS:
				return QUARTERS;
			default: {
				return storedTimelineFilter || MONTHS;
			}
		}
	},
);

const isViewVisible = (isVisibleInQuery: boolean, isVisibleInStore: boolean): boolean =>
	!isVisibleInQuery ? false : isVisibleInStore;

export const isDependenciesVisible = createSelector(
	isDependenciesVisibleInQuery,
	isDependenciesVisibleInView,
	isViewVisible,
);

export const isProgressVisible = createSelector(
	isProgressVisibleInQuery,
	isProgressVisibleInView,
	isViewVisible,
);

export const isWarningsVisible: (state: State) => boolean = createSelector(
	isWarningsVisibleInQuery,
	isWarningsVisibleInView,
	isViewVisible,
);

export const getLevelOneSetting = createSelector(
	getEpicSettingInQuery,
	getLevelOneSettingInView,
	getLevelOneSettingPure,
);

// ======================= //
// ====== ISSUE TYPE ===== //
// ======================= //

export const getChildIssueTypes = createSelector(
	getFullIssueTypeHash,
	getEpicIssueTypeIds,
	getChildIssueTypesPure,
);
