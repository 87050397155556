import React, { type ComponentPropsWithoutRef } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { ROADMAP_PACKAGE_NAME } from '../../../../../constants.tsx';
import type RoadmapEditDependencyModal from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const EditDependencyModal = lazyAfterPaint<typeof RoadmapEditDependencyModal>(
	() => import(/* webpackChunkName: "async-standard-roadmap-edit-dependency-modal" */ './index'),
	{ ssr: false },
);

type Props = JSX.LibraryManagedAttributes<
	typeof RoadmapEditDependencyModal,
	ComponentPropsWithoutRef<typeof RoadmapEditDependencyModal>
>;

export const RoadmapEditDependencyModalAsync = (props: Props) => (
	<JSErrorBoundary
		id="async-standard-roadmap-edit-dependency-modal"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="async-standard-roadmap-edit-dependency-modal" fallback={null}>
			<EditDependencyModal {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
