import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	changeIssueColor: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.change-color-option.change-issue-color',
		defaultMessage: 'Change issue color',
		description: 'Label for change issue color option',
	},
	changeIssueColorIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.change-color-option.change-issue-color-issue-term-refresh',
		defaultMessage: 'Change work item color',
		description: 'Label for change issue color option',
	},
});

export default messages;
