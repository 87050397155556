import type { CustomFilterId as CustomFilterTypeId } from '@atlassian/jira-filters/src/common/types.tsx';
import type {
	ComponentId,
	IssueTypeId,
	AccountId,
	VersionId,
	IssueStatusCategoryId,
	IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { FilterConfiguration } from '@atlassian/jira-software-roadmap-model/src/configuration/index.tsx';
import type { QuickFilterId } from '@atlassian/jira-software-roadmap-model/src/quick-filter/index.tsx';

import type { TimelineMode } from '@atlassian/timeline-table/common/types/timeline';

export const SET_TIMELINE_MODE = 'state.ui.filters.SET_TIMELINE_MODE' as const;

export type SetTimelineModeAction = {
	type: typeof SET_TIMELINE_MODE;
	payload: TimelineMode;
};

export const setTimelineMode = (payload: TimelineMode): SetTimelineModeAction => ({
	type: SET_TIMELINE_MODE,
	payload,
});

export const SET_STATUS_CATEGORY_FILTER = 'state.ui.filters.SET_STATUS_CATEGORY_FILTER' as const;

export type SetStatusCategoryFilterActionPayload = IssueStatusCategoryId[];

export type SetStatusCategoryFilterAction = {
	type: typeof SET_STATUS_CATEGORY_FILTER;
	payload: SetStatusCategoryFilterActionPayload;
};

export const setStatusCategoryFilter = (
	payload: SetStatusCategoryFilterActionPayload,
): SetStatusCategoryFilterAction => ({
	type: SET_STATUS_CATEGORY_FILTER,
	payload,
});

export const SET_ISSUE_TYPE_FILTER = 'state.ui.filters.SET_ISSUE_TYPE_FILTER' as const;

export type SetIssueTypeFilterActionPayload = IssueTypeId[];

export type SetIssueTypeFilterAction = {
	type: typeof SET_ISSUE_TYPE_FILTER;
	payload: SetIssueTypeFilterActionPayload;
};

export const setIssueTypeFilter = (
	payload: SetIssueTypeFilterActionPayload,
): SetIssueTypeFilterAction => ({
	type: SET_ISSUE_TYPE_FILTER,
	payload,
});

export const SET_ASSIGNEE_FILTER = 'state.ui.filters.SET_ASSIGNEE_FILTER' as const;

export type SetAssigneeFilterAction = {
	type: typeof SET_ASSIGNEE_FILTER;
	payload: AccountId[];
};

export const setAssigneeFilter = (payload: AccountId[]): SetAssigneeFilterAction => ({
	type: SET_ASSIGNEE_FILTER,
	payload,
});

export const CLEAR_FILTER = 'state.ui.filters.CLEAR_FILTER' as const;

export type ClearFilterAction = {
	type: typeof CLEAR_FILTER;
};

export const clearFilter = (): ClearFilterAction => ({
	type: CLEAR_FILTER,
});

export const CLEAR_FILTER_AND_SETTING = 'state.ui.filters.CLEAR_FILTER_AND_SETTING' as const;

export type ClearFilterAndSettingAction = {
	type: typeof CLEAR_FILTER_AND_SETTING;
};

export const clearFilterAndSetting = (): ClearFilterAndSettingAction => ({
	type: CLEAR_FILTER_AND_SETTING,
});

export const SET_TEXT_FILTER = 'state.ui.filters.SET_TEXT_FILTER' as const;

export type SetTextFilterAction = {
	type: typeof SET_TEXT_FILTER;
	payload: string;
};

export const setTextFilter = (payload: string): SetTextFilterAction => ({
	type: SET_TEXT_FILTER,
	payload,
});

export const SET_LABEL_FILTER = 'state.ui.filters.SET_LABEL_FILTER' as const;

export type SetLabelFilterAction = {
	type: typeof SET_LABEL_FILTER;
	payload: string[];
};

export const setLabelFilter = (payload: string[]): SetLabelFilterAction => ({
	type: SET_LABEL_FILTER,
	payload,
});

export const SET_ISSUE_PARENT_FILTER = 'state.ui.filters.SET_ISSUE_PARENT_FILTER' as const;

export type SetIssueParentFilterAction = {
	type: typeof SET_ISSUE_PARENT_FILTER;
	payload: IssueId[];
};

export const setIssueParentFilter = (payload: IssueId[]): SetIssueParentFilterAction => ({
	type: SET_ISSUE_PARENT_FILTER,
	payload,
});

export const SET_VERSION_FILTER = 'state.ui.filters.SET_VERSION_FILTER' as const;

export type SetVersionFilterAction = {
	type: typeof SET_VERSION_FILTER;
	payload: VersionId[];
};

export const setVersionFilter = (payload: VersionId[]): SetVersionFilterAction => ({
	type: SET_VERSION_FILTER,
	payload,
});

export const SET_COMPONENT_FILTER = 'state.ui.filters.SET_COMPONENT_FILTER' as const;

export type SetComponentFilterAction = {
	type: typeof SET_COMPONENT_FILTER;
	payload: ComponentId[];
};

export const setComponentFilter = (payload: ComponentId[]): SetComponentFilterAction => ({
	type: SET_COMPONENT_FILTER,
	payload,
});

export const SET_QUICK_FILTERS_FILTER = 'state.ui.filters.SET_QUICK_FILTERS_FILTER' as const;

export type SetQuickFiltersFilterAction = {
	type: typeof SET_QUICK_FILTERS_FILTER;
	payload: QuickFilterId[];
};

export const setQuickFiltersFilter = (payload: QuickFilterId[]): SetQuickFiltersFilterAction => ({
	type: SET_QUICK_FILTERS_FILTER,
	payload,
});

export const SET_FILTER_CONFIGURATION = 'state.ui.filters.SET_FILTER_CONFIGURATION' as const;

export type SetFilterConfigurationAction = {
	type: typeof SET_FILTER_CONFIGURATION;
	payload: FilterConfiguration;
};

export const setFilterConfiguration = (
	payload: FilterConfiguration,
): SetFilterConfigurationAction => ({
	type: SET_FILTER_CONFIGURATION,
	payload,
});

// === Quick Filters ===

export const QUICK_FILTERS_REQUEST = 'state.ui.filters.QUICK_FILTERS_REQUEST' as const;

export type QuickFiltersRequestAction = {
	type: typeof QUICK_FILTERS_REQUEST;
	payload: string[];
};

export const quickFiltersRequest = (payload: string[]): QuickFiltersRequestAction => ({
	type: QUICK_FILTERS_REQUEST,
	payload,
});

export const JQL_FILTERS_SUCCESS = 'state.ui.filters.JQL_FILTERS_SUCCESS' as const;

export type JQLFiltersSuccessAction = {
	type: typeof JQL_FILTERS_SUCCESS;
	payload: IssueId[];
};

export const jqlFiltersSuccess = (payload: IssueId[]): JQLFiltersSuccessAction => ({
	type: JQL_FILTERS_SUCCESS,
	payload,
});

export const QUICK_FILTERS_CLEAR = 'state.ui.filters.QUICK_FILTERS_CLEAR' as const;

export type QuickFiltersClearAction = {
	type: typeof QUICK_FILTERS_CLEAR;
};

export const clearQuickFilters = (): QuickFiltersClearAction => ({
	type: QUICK_FILTERS_CLEAR,
});

export const JQL_FILTERS_REAPPLY = 'state.ui.filters.JQL_FILTERS_REAPPLY' as const;

export type JQLFiltersReapplyAction = {
	type: typeof JQL_FILTERS_REAPPLY;
};

export const reapplyJQLFilters = (): JQLFiltersReapplyAction => ({
	type: JQL_FILTERS_REAPPLY,
});

// === Custom Filters ===

export const SET_CUSTOM_FILTER = 'state.ui.filters.SET_CUSTOM_FILTER' as const;

export type SetCustomFilterAction = {
	type: typeof SET_CUSTOM_FILTER;
	payload: CustomFilterTypeId[];
};

export const setCustomFilter = (payload: CustomFilterTypeId[]): SetCustomFilterAction => ({
	type: SET_CUSTOM_FILTER,
	payload,
});

export const CUSTOM_FILTERS_REQUEST = 'state.ui.filters.CUSTOM_FILTERS_REQUEST' as const;

export type CustomFiltersRequestAction = {
	type: typeof CUSTOM_FILTERS_REQUEST;
	payload: string[];
};

export const customFiltersRequest = (payload: string[]): CustomFiltersRequestAction => ({
	type: CUSTOM_FILTERS_REQUEST,
	payload,
});

export const CUSTOM_FILTERS_CLEAR = 'state.ui.filters.CUSTOM_FILTERS_CLEAR' as const;

export type CustomFiltersClearAction = {
	type: typeof CUSTOM_FILTERS_CLEAR;
};

export const clearCustomFilters = (): CustomFiltersClearAction => ({
	type: CUSTOM_FILTERS_CLEAR,
});

export const SET_GOAL_FILTER = 'state.ui.filters.SET_GOAL_FILTER' as const;

export type SetGoalFilterAction = {
	type: typeof SET_GOAL_FILTER;
	payload: string[];
};

export const setGoalFilter = (payload: string[]): SetGoalFilterAction => ({
	type: SET_GOAL_FILTER,
	payload,
});
