import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import TodayButton from '@atlassian/jira-aais-timeline-toolbar/src/ui/today-button/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';

// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useTimelineState as useTimelineStateOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import { timelineScroller } from '@atlassian/jira-software-roadmap-utils/src/utils/timeline-scroller.tsx';
import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';

const RoadmapTodayButtonOld = () => {
	const [{ timelineOriginPosition }] = useTimelineStateOld();

	const onClickCallback = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'todayButton');
			timelineScroller.scrollTo({ left: timelineOriginPosition });
		},
		[timelineOriginPosition],
	);

	return <TodayButton onClick={onClickCallback} />;
};

const RoadmapTodayButtonNew = () => {
	const [{ timelineOriginPosition }] = useTimelineState();

	const onClickCallback = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'todayButton');
			timelineScroller.scrollTo({ left: timelineOriginPosition });
		},
		[timelineOriginPosition],
	);

	return <TodayButton onClick={onClickCallback} />;
};

const RoadmapTodayButton = componentWithFG(
	'switch_timeline_table_imports',
	RoadmapTodayButtonNew,
	RoadmapTodayButtonOld,
);

export { RoadmapTodayButton };
