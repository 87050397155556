import React from 'react';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';
import type { CacheInvalidationType } from '../../common/types.tsx';
import useResourcesCacheInvalidation from '../../controllers/use-resources-cache-invalidation/index.tsx';
import useCacheRefreshAnalytics from '../../controllers/use-resources-cache-refresh-analytics/index.tsx';
import useResourcesCacheUpdater from '../../controllers/use-resources-cache-updater/index.tsx';

type Props = {
	origin: CacheInvalidationType;
};

const ResourcesCacheWrapper = ({ origin }: Props) => {
	useResourcesCacheInvalidation(origin);
	useResourcesCacheUpdater();
	useCacheRefreshAnalytics(origin);

	return <></>;
};

const ResourcesCacheWrapperWithBoundary = (props: Props) => (
	<ErrorBoundary id="jira-software-resource-invalidator">
		<ResourcesCacheWrapper {...props} />
	</ErrorBoundary>
);

export default ResourcesCacheWrapperWithBoundary;
