import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';

import { graphql } from 'react-relay';
import type { Observable } from 'rxjs/Observable';
import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type {
	addRoadmapItemMutation$data,
	RoadmapAddItemInput,
	addRoadmapItemMutation,
} from '@atlassian/jira-relay/src/__generated__/addRoadmapItemMutation.graphql';
import { PERSIST_ITEM } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { runMutation } from '../common/agg/run-mutation.tsx';
import { mutationMetrics } from '../performance-analytics/index.tsx';

type InputParams = {
	sourceARI: Ari;
	input: RoadmapAddItemInput;
};

const concurrentMetricDefinition = mutationMetrics[PERSIST_ITEM];
const analyticKey = 'jira.frontend.fe.software.roadmap.persist-issue';
const mutationName = 'addRoadmapItem';

export const create = ({
	sourceARI,
	input,
}: InputParams): Observable<addRoadmapItemMutation$data> =>
	runMutation<addRoadmapItemMutation>(
		mutationName,
		graphql`
			mutation addRoadmapItemMutation(
				$sourceARI: ID!
				$input: RoadmapAddItemInput!
				$isRoadmapsCriticalDataItemFlaggedEnabled: Boolean!
			) {
				roadmaps {
					addRoadmapItem(sourceARI: $sourceARI, input: $input) {
						success
						errors {
							message
							extensions {
								... on RoadmapMutationErrorExtension {
									statusCode
									errorType
								}
							}
						}
						output {
							id
							key
							matchesSource
							matchesJqlFilters
							item {
								id
								key
								parentId
								summary
								startDateRFC3339
								dueDateRFC3339
								color
								dependencies
								itemTypeId
								assignee {
									accountId
									name
									picture
								}
								status {
									id
									name
									statusCategory {
										id
									}
								}
								rank
								labels
								sprintIds
								versionIds
								componentIds
								resolved
								flagged @include(if: $isRoadmapsCriticalDataItemFlaggedEnabled)
							}
						}
					}
				}
			}
		`,
		{
			sourceARI,
			input,
			isRoadmapsCriticalDataItemFlaggedEnabled: fg('jsw-timeline-return-flagged-attribute-fe'),
		},
		analyticKey,
		concurrentMetricDefinition,
	);
