import memoizeOne from 'memoize-one';

import type { ItemId, ItemsSelectionState } from '../types/item';

// Remove with project_timeline_multi-select_and_checkboxes cleaning up
export const getSelectedItemIdsHash = memoizeOne(
	(selectedItemIdsWithPermission: ItemId[] | undefined): ItemsSelectionState =>
		selectedItemIdsWithPermission
			? selectedItemIdsWithPermission.reduce(
					(itemsSelectionState: NonNullable<ItemsSelectionState>, selectedItemId: ItemId) => {
						Object.assign(itemsSelectionState, { [selectedItemId]: true });

						return itemsSelectionState;
					},
					{},
				)
			: undefined,
);
