// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { ROADMAP_PACKAGE_NAME } from '../../../../constants.tsx';
import type Resizer from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const ResizerLazy = lazyForPaint<typeof Resizer>(
	() => import(/* webpackChunkName: "async-standard-roadmap-resizer" */ './index'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	props: JSX.LibraryManagedAttributes<typeof Resizer, ComponentProps<typeof Resizer>>,
) => (
	<JSErrorBoundary id="async-standard-roadmap-feedback" packageName={ROADMAP_PACKAGE_NAME}>
		<Placeholder name="standard-roadmap-resizer" fallback={null}>
			<ResizerLazy {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
