import { createContainer, createHook, createStore } from 'react-sweet-state';

import { scrollMetaActions } from './actions';
import type { Actions, ContainerProps, ScrollMeta } from './common/types';
import { getIsScrollingX, getIsScrollingY, getIsScrollToTop } from './selectors';

const DEFAULT_STATE = {
	isScrollingX: false,
	isScrollingY: false,
	positions: { top: 0, bottom: 0 },
} as const;

const store = createStore<ScrollMeta, Actions>({
	initialState: DEFAULT_STATE,
	actions: scrollMetaActions,
	name: 'timeline-table.scroll-meta',
});

export const useScrollMetaActions = createHook(store, {
	selector: null,
});

export const ScrollMetaProviderSweetState = createContainer<ScrollMeta, Actions, ContainerProps>(
	store,
);

export const isScrollToTop = createHook(store, {
	selector: getIsScrollToTop,
});

export const useIsScrollingY = createHook(store, {
	selector: getIsScrollingY,
});

export const useIsScrollingX = createHook(store, {
	selector: getIsScrollingX,
});
