import React, { type ComponentPropsWithoutRef } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { ROADMAP_PACKAGE_NAME } from '../../../../../constants.tsx';
import type RoadmapBulkEditDatesModal from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const BulkEditDatesModal = lazyAfterPaint<typeof RoadmapBulkEditDatesModal>(
	() => import(/* webpackChunkName: "async-standard-roadmap-bulk-edit-dates-modal" */ './index'),
	{ ssr: false },
);

type Props = JSX.LibraryManagedAttributes<
	typeof RoadmapBulkEditDatesModal,
	ComponentPropsWithoutRef<typeof RoadmapBulkEditDatesModal>
>;

export const RoadmapBulkEditDatesModalAsync = (props: Props) => (
	<JSErrorBoundary
		id="async-standard-roadmap-bulk-edit-dates-modal"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="async-standard-roadmap-bulk-edit-dates-modal" fallback={null}>
			<BulkEditDatesModal {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
