import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Modifiable } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type { Issue } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';

import { INSIDE, LAST } from '@atlassian/timeline-table/common/constants';
import {
	EXTEND_ISSUES,
	type ExtendIssuesAction,
	REMOVE_ISSUE,
	type RemoveIssueAction,
	REMOVE_ISSUES,
	type RemoveIssuesAction,
	INSERT_ISSUE,
	type InsertIssueAction,
	REPLACE_ISSUE,
	type ReplaceIssueAction,
	RANK_ISSUE,
	type RankIssueAction,
	ADD_DEPENDENCY,
	type AddDependencyAction,
	DELETE_DEPENDENCY,
	type DeleteDependencyAction,
	RESET_TRANSITION_STATE,
	type ResetTransitionStateAction,
	CLEAR_TRANSITION_STATE,
	type ClearTransitionStateAction,
	UPDATE_ISSUES_SEQUENCE,
	type UpdateIssuesSequenceAction,
	UPDATE_ISSUES_HASH_AND_SEQUENCE,
	type UpdateIssuesHashAndSequenceAction,
} from './actions.tsx';
import type { IssuesState } from './types.tsx';

export type Action =
	| ExtendIssuesAction
	| RemoveIssueAction
	| RemoveIssuesAction
	| InsertIssueAction
	| ReplaceIssueAction
	| RankIssueAction
	| AddDependencyAction
	| DeleteDependencyAction
	| ResetTransitionStateAction
	| ClearTransitionStateAction
	| UpdateIssuesSequenceAction
	| UpdateIssuesHashAndSequenceAction;

const initialState: IssuesState = {
	sequence: [],
	hash: {},
};

const clearTransitionForField = <T,>(field: Modifiable<T>, transitionId: string): Modifiable<T> => {
	if ('transitionId' in field && field.transitionId === transitionId) {
		return {
			value: field.value,
		};
	}
	return field;
};

const resetTransitionForField = <T,>(field: Modifiable<T>, transitionId: string): Modifiable<T> => {
	if ('transitionId' in field && field.transitionId === transitionId) {
		return {
			value: field.old,
		};
	}
	return field;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssuesState = initialState, action: Action): IssuesState => {
	switch (action.type) {
		case EXTEND_ISSUES: {
			const { payload } = action;
			return {
				sequence: union(state.sequence, payload.sequence),
				hash: {
					...state.hash,
					...payload.hash,
				},
			};
		}

		case UPDATE_ISSUES_SEQUENCE: {
			const { payload } = action;

			return {
				sequence: payload,
				hash: state.hash,
			};
		}

		case UPDATE_ISSUES_HASH_AND_SEQUENCE: {
			const { payload } = action;
			return {
				sequence: payload.sequence,
				hash: payload.hash,
			};
		}

		case REMOVE_ISSUE:
			return {
				sequence: without(state.sequence, action.payload),
				hash: pickBy(state.hash, (item: Issue, key: IssueId) => key !== action.payload),
			};

		case REMOVE_ISSUES:
			return {
				sequence: without(state.sequence, ...action.payload),
				hash: pickBy(state.hash, (item: Issue, key: IssueId) => !action.payload.includes(key)),
			};

		case INSERT_ISSUE: {
			const { id, issue, anchor } = action.payload;
			const sequence = [...state.sequence];

			/* Children will always be added last inside their parent.
			 * For our purposes, this can be accomplished by inserting last into the whole sequence.
			 */
			if (anchor.position === LAST || anchor.position === INSIDE) {
				sequence.push(id);
			} else {
				sequence.splice(anchor?.beforeId ? sequence.indexOf(anchor.beforeId) : -1, 0, id);
			}

			return {
				sequence,
				hash: {
					...state.hash,
					[`${id}`]: issue,
				},
			};
		}

		case REPLACE_ISSUE: {
			const { temporaryId, id, issue } = action.payload;

			return {
				sequence: state.sequence.map((existingId) =>
					existingId === temporaryId ? id : existingId,
				),
				hash: {
					...omit(state.hash, [temporaryId]),
					[`${id}`]: issue,
				},
			};
		}

		case RANK_ISSUE: {
			const { issueId, rankRequest } = action.payload;
			const { sequence } = state;
			let anchorId;
			let isBefore = true;

			if ('rankBeforeId' in rankRequest) {
				anchorId = rankRequest.rankBeforeId;
			} else {
				isBefore = false;
				anchorId = rankRequest.rankAfterId;
			}

			const issueIndex = sequence.indexOf(issueId);
			// remove issueId
			sequence.splice(issueIndex, 1);
			let anchorIndex = sequence.indexOf(anchorId);

			if (isBefore === false) {
				anchorIndex += 1;
			}
			// insert issueId at new position
			sequence.splice(anchorIndex, 0, issueId);

			return {
				...state,
				sequence: [...sequence],
			};
		}

		case ADD_DEPENDENCY: {
			const { dependee, dependency } = action.payload;
			const { hash } = state;

			if (hash[dependee]) {
				return {
					...state,
					hash: {
						...hash,
						[dependee]: {
							...hash[dependee],
							dependencies: uniq([...(hash[dependee].dependencies || []), dependency]),
						},
					},
				};
			}

			// ignore if target issue does not exists
			return state;
		}

		case DELETE_DEPENDENCY: {
			const { dependee, dependency } = action.payload;
			const { hash } = state;

			if (hash[dependee]) {
				return {
					...state,
					hash: {
						...hash,
						[dependee]: {
							...hash[dependee],
							dependencies: hash[dependee].dependencies.filter(
								(dependencyId) => dependencyId !== dependency,
							),
						},
					},
				};
			}

			// ignore if target issue does not exists
			return state;
		}

		case CLEAR_TRANSITION_STATE: {
			const { issueId, transitionId } = action.payload;
			const { hash } = state;
			const issue = hash[issueId];

			if (issue !== undefined) {
				return {
					...state,
					hash: {
						...hash,
						[issueId]: {
							...issue,
							summary: clearTransitionForField(issue.summary, transitionId),
							startDate: clearTransitionForField(issue.startDate, transitionId),
							dueDate: clearTransitionForField(issue.dueDate, transitionId),
							color: clearTransitionForField(issue.color, transitionId),
							parentId: clearTransitionForField(issue.parentId, transitionId),
							sprintIds: clearTransitionForField(issue.sprintIds, transitionId),
						},
					},
				};
			}

			return state;
		}

		case RESET_TRANSITION_STATE: {
			const { issueId, transitionId } = action.payload;
			const { hash } = state;
			const issue = hash[issueId];

			if (issue !== undefined) {
				return {
					...state,
					hash: {
						...hash,
						[issueId]: {
							...issue,
							summary: resetTransitionForField(issue.summary, transitionId),
							startDate: resetTransitionForField(issue.startDate, transitionId),
							dueDate: resetTransitionForField(issue.dueDate, transitionId),
							color: resetTransitionForField(issue.color, transitionId),
							parentId: resetTransitionForField(issue.parentId, transitionId),
							sprintIds: resetTransitionForField(issue.sprintIds, transitionId),
						},
					},
				};
			}

			return state;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
