import type React from 'react';

import makeRange from 'lodash/fp/range';
import type { Action } from 'react-sweet-state';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

import { getItemLevel } from '../../../utils/hierarchy-enriched-items';
import type { State } from '../types';

type ToggleItemParams = {
	analyticsEvent: UIAnalyticsEvent;
	deselectOthers?: boolean;
	withRange?: boolean;
};

export const actions = {
	toggleItem:
		(id: string, { analyticsEvent, deselectOthers, withRange }: ToggleItemParams): Action<State> =>
		({ setState, getState }) => {
			const {
				items,
				selectedItemIds,
				activeItemId,
				rangeAnchorItemId = activeItemId,
				itemIndexes,
				onItemMultiSelect,
			} = getState();

			const current = new Set(selectedItemIds);
			const next: typeof current = deselectOthers ? new Set() : current;

			/** Resets when any of selected items no longer available. */
			if (selectedItemIds.some((itemId) => itemIndexes[itemId] === undefined)) {
				next.clear();
			}

			const selected = !current.has(id);
			const anchorIndex =
				withRange && rangeAnchorItemId ? itemIndexes[rangeAnchorItemId] : undefined;
			const targetIndex = withRange ? itemIndexes[id] : undefined;

			if (anchorIndex !== undefined && targetIndex !== undefined) {
				const anchorItem = items[anchorIndex];

				let itemIdsInRange = [];

				/** Indicates whether the range-select is cancelled due to cross parent selecting. */
				let isRangeCancelled = false;

				const indexRange = [...makeRange(anchorIndex, targetIndex), targetIndex];

				for (const index of indexRange) {
					const item = items[index];

					if ('item' in item && getItemLevel(item) === getItemLevel(anchorItem)) {
						itemIdsInRange.push(item.item.id);
					}

					/** Cancels if multi-select across the a higher-level issue. */
					if (getItemLevel(item) > getItemLevel(anchorItem)) {
						isRangeCancelled = true;
						break;
					}
				}

				if (isRangeCancelled) {
					itemIdsInRange = [id];
				}

				itemIdsInRange.forEach((itemIdInRange) => {
					selected ? next.add(itemIdInRange) : next.delete(itemIdInRange);
				});
			} else {
				selected ? next.add(id) : next.delete(id);
			}

			setState({ rangeAnchorItemId: id });
			onItemMultiSelect(analyticsEvent, [...next]);
		},

	activateItem:
		(id: string, ref?: React.RefObject<HTMLElement>): Action<State> =>
		({ getState, setState }) => {
			getState().onItemActivate(id, ref);
			setState({ rangeAnchorItemId: id });
		},
};

type Actions = typeof actions;

export type { Actions };
