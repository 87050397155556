import { createSelector } from 'reselect';

import type { TimelineMode } from '@atlassian/timeline-table/common/types/timeline';
import { hasMacroConfig } from '../app/selectors.tsx';
import {
	isDependenciesVisible,
	isProgressVisible,
	getTimelineMode,
	getNumJQLFiltersApplied,
} from './filters/index.tsx';
import { getFilteredIssueCount } from './issues/index.tsx';
import { getIsReleasesVisible } from './versions/index.tsx';

export type InteractionPerformanceExtraData = {
	timelineMode: TimelineMode;
	filteredIssueCount: number;
	numJQLFiltersApplied: number;
	dependenciesVisible: boolean;
	progressVisible: boolean;
	releasesVisible: boolean;
	isMacro: boolean;
};

/**
 * getInteractionPerformanceExtraData selects data which will be attached to
 * interaction performance metrics.
 */
export const getInteractionPerformanceExtraData = createSelector(
	getTimelineMode,
	getFilteredIssueCount,
	getNumJQLFiltersApplied,
	isDependenciesVisible,
	isProgressVisible,
	getIsReleasesVisible,
	hasMacroConfig,
	(
		timelineMode: TimelineMode,
		filteredIssueCount: number,
		numJQLFiltersApplied: number,
		dependenciesVisible: boolean,
		progressVisible: boolean,
		releasesVisible: boolean,
		isMacro: boolean,
	) => ({
		timelineMode,
		filteredIssueCount,
		numJQLFiltersApplied,
		dependenciesVisible,
		progressVisible,
		releasesVisible,
		isMacro,
	}),
);
