/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { TOOLTIP_DATE_FORMAT } from '@atlassian/jira-software-roadmap-model/src/datetime/index.tsx';
import { getDateFromTimestamp } from '@atlassian/jira-software-roadmap-utils/src/utils/dates.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { EXPLICIT } from '../../constants/date.tsx';
import { LEFT, RIGHT, LEFT_AND_RIGHT } from '../../constants/index.tsx';
import { CHART_DATES_Z_INDEX } from '../../constants/z-index.tsx';
import type { Placeholder } from '../../types/chart-item.tsx';
import type { DateType } from '../../types/date.tsx';
import { isStartDatePlaceholder, isDueDatePlaceholder } from '../../utils/bar/placeholder.tsx';
import { Duration } from '../date-labels/duration/index.tsx';
import { DATE_ICON_LOOKUP, DATE_CONTAINER_LOOKUP } from './constants.tsx';
import messages from './messages.tsx';
import type { Placement } from './types.tsx';

type Props = {
	isDurationVisible?: boolean;
	startDate?: number;
	dueDate?: number;
	startDateType?: DateType;
	dueDateType?: DateType;
	placeholder?: Placeholder;
	placement?: Placement;
	renderDuration?: () => ReactNode;
};

/* Combines timestamps into a human readable label that changes based upon the date type (see props).
 * - If a date is not provided it will *not* be rendered.
 * - By providing a placement, the dates will be rendered absolute to either side of their nearest relative ancestor.
 */
const FormattedDate = ({
	startDate,
	dueDate,
	startDateType = EXPLICIT,
	dueDateType = EXPLICIT,
	placeholder,
	isDurationVisible = false,
	placement,
	renderDuration,
}: Props) => {
	const { formatMessage } = useIntl();

	const startDatePlaceholderMessage = isStartDatePlaceholder(placeholder)
		? formatMessage(messages.noStartDate)
		: undefined;

	const dueDatePlaceholderMessage = isDueDatePlaceholder(placeholder)
		? formatMessage(messages.noDueDate)
		: undefined;

	// === RENDER === //

	const renderDurationLabel = (shouldRender = true) => {
		const isDurationValid = startDate !== undefined || dueDate !== undefined;

		return isDurationVisible && isDurationValid && shouldRender ? (
			<>
				{isVisualRefreshEnabled() && fg('visual-refresh_drop_1') ? undefined : ' '}
				{renderDuration ? (
					renderDuration()
				) : (
					<Duration fromDate={startDate ?? 0} toDate={dueDate ?? 0} />
				)}
			</>
		) : null;
	};

	const renderDate = (dateType: DateType, date?: number, placeholderMessage?: string) => {
		if (date === undefined) {
			return null;
		}

		const DateContainer = DATE_CONTAINER_LOOKUP[dateType];
		const DateIcon = DATE_ICON_LOOKUP[dateType];

		const visuallyRefreshedDate = placeholderMessage ? (
			<small css={placementOuterContainerStyles}>{placeholderMessage}</small>
		) : (
			<DateContainer>{getDateFromTimestamp(date, TOOLTIP_DATE_FORMAT)}</DateContainer>
		);

		return (
			<>
				<DateIcon />
				{isVisualRefreshEnabled() && fg('visual-refresh_drop_1') ? (
					visuallyRefreshedDate
				) : (
					<>{placeholderMessage ?? <DateContainer>{getDateFromTimestamp(date)}</DateContainer>}</>
				)}
			</>
		);
	};

	if (placement === LEFT_AND_RIGHT) {
		const startDateContent = renderDate(startDateType, startDate, startDatePlaceholderMessage);
		const endDateContent = renderDate(dueDateType, dueDate, dueDatePlaceholderMessage);

		return (
			<>
				{isVisualRefreshEnabled() && fg('visual-refresh_drop_1') ? (
					<>
						<small
							css={[
								outerContainerStyles,
								startDateContent !== null && outerContainerExtraPaddingStyles,
								defaultPlacementStyles,
								placemenetLeftStyles,
							]}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="notranslate"
						>
							{startDateContent}
							{renderDurationLabel(startDate !== undefined && dueDate === undefined)}
						</small>
						<small
							css={[
								outerContainerStyles,
								endDateContent !== null && outerContainerExtraPaddingStyles,
								defaultPlacementStyles,
								placemenetRightStyles,
							]}
							// eslint-disable-next-line  @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="notranslate"
						>
							{endDateContent}
							{renderDurationLabel(dueDate !== undefined)}
						</small>
					</>
				) : (
					<>
						<small
							css={[outerContainerStylesOld, defaultPlacementStyles, placemenetLeftStylesOld]}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="notranslate"
						>
							{startDateContent}
							{renderDurationLabel(startDate !== undefined && dueDate === undefined)}
						</small>
						<small
							css={[outerContainerStylesOld, defaultPlacementStyles, placemenetRightStylesOld]}
							// eslint-disable-next-line  @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="notranslate"
						>
							{endDateContent}
							{renderDurationLabel(dueDate !== undefined)}
						</small>
					</>
				)}
			</>
		);
	}

	const content = (
		<>
			{renderDate(startDateType, startDate, startDatePlaceholderMessage)}
			{startDate !== undefined && dueDate !== undefined && ' - '}
			{renderDate(dueDateType, dueDate, dueDatePlaceholderMessage)}
			{renderDurationLabel()}
		</>
	);

	return (
		<>
			{isVisualRefreshEnabled() && fg('visual-refresh_drop_1') ? (
				<small
					css={[
						outerContainerStyles,
						outerContainerExtraPaddingStyles,
						placement && defaultPlacementStyles,

						placement === LEFT && placemenetLeftStyles,

						placement === RIGHT && placemenetRightStyles,
					]}
					data-testid="roadmap.timeline-table-kit.common.ui.formatted-date.formatted-date"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="notranslate"
				>
					{content}
				</small>
			) : (
				<small
					css={[
						outerContainerStylesOld,
						placement && defaultPlacementStyles,

						placement === LEFT && placemenetLeftStylesOld,

						placement === RIGHT && placemenetRightStylesOld,
					]}
					data-testid="roadmap.timeline-table-kit.common.ui.formatted-date.formatted-date"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="notranslate"
				>
					{content}
				</small>
			)}
		</>
	);
};

export { FormattedDate };

const placemenetLeftStyles = css({
	right: '100%',
	marginRight: token('space.025', '2px'),
});

const placemenetRightStyles = css({
	left: '100%',
	marginLeft: token('space.025', '2px'),
});

const placemenetLeftStylesOld = css({
	right: '100%',
	paddingRight: token('space.100', '8px'),
});

const placemenetRightStylesOld = css({
	left: '100%',
	paddingLeft: token('space.100', '8px'),
});

const defaultPlacementStyles = css({
	position: 'absolute',
});

const placementOuterContainerStyles = css({
	whiteSpace: 'pre',
	color: token('color.text.inverse', 'rgba(255, 255, 255, 0.9)'),
});

const outerContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: 0,
	whiteSpace: 'pre',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_DATES_Z_INDEX,
	// span selector applies lowercase styles to the duration badge
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		textTransform: 'lowercase',
	},
	backgroundColor: token('color.background.neutral.bold', 'rgba(9, 30, 66, 0.04)'),
	color: token('color.text.inverse', 'rgba(255, 255, 255, 0.9)'),
	borderRadius: '3px',
});

const outerContainerExtraPaddingStyles = css({
	padding: '2px 6px 2px 6px',
});

const outerContainerStylesOld = css({
	display: 'flex',
	alignItems: 'center',
	marginTop: 0,
	textTransform: 'uppercase',
	whiteSpace: 'pre',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_DATES_Z_INDEX,
	// span selector applies lowercase styles to the duration badge
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		textTransform: 'lowercase',
	},
});
