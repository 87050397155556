import { createSelector } from 'reselect';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Hash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';

import {
	BASE_LEVEL_ITEM_HEIGHT,
	BASE_HEADER_HEIGHT,
	BEFORE,
	INSIDE,
} from '@atlassian/timeline-table/common/constants';
import type { CreateItemAnchor } from '@atlassian/timeline-table/common/types/create';
import type { ExpandedItems } from '@atlassian/timeline-table/common/types/item';

type ItemOffset = {
	id: string;
	// the middle position of the item
	position: number;
	height: number;
};

export const getItemsOffsets = (
	headerItemsHeight: number,
	itemHeight: number,
	itemIds: IssueId[],
	childIdsHash: Hash<IssueId[]> = {},
	expandedItems: ExpandedItems = {},
	createItemAnchor: CreateItemAnchor,
): ItemOffset[] => {
	const result: Array<ItemOffset> = [];
	let prevItemOffset = 0;

	itemIds.forEach((id: IssueId) => {
		const childIds = childIdsHash[id] || [];
		let childHeight = expandedItems[id] ? childIds.length * BASE_LEVEL_ITEM_HEIGHT() : 0;
		let currentOffset = prevItemOffset;

		/* Include offsets caused by the create item.
		 * The create item is always the same height (itemHeight) even when creating children.
		 */
		if (createItemAnchor) {
			const createBeforeItem =
				createItemAnchor.position === BEFORE && createItemAnchor.beforeId === id;

			const createBeforeChildItem =
				createItemAnchor.position === BEFORE &&
				createItemAnchor.beforeId === id &&
				createItemAnchor.parentId !== undefined;

			const createInsideItem =
				createItemAnchor.position === INSIDE && createItemAnchor.parentId === id;

			if (createBeforeItem) {
				currentOffset += itemHeight;
			}

			if (createInsideItem || createBeforeChildItem) {
				childHeight += itemHeight;
			}
		}

		const totalHeight = itemHeight + childHeight;

		result.push({
			id: String(id),
			position: currentOffset + itemHeight / 2,
			height: itemHeight,
		});

		// if parent is expanded, include children in the list
		if (expandedItems[id]) {
			let childPrevItemOffset = currentOffset + itemHeight;

			childIds.forEach((childId: IssueId) => {
				if (
					createItemAnchor &&
					typeof createItemAnchor === 'object' &&
					'parentId' in createItemAnchor &&
					createItemAnchor.parentId &&
					createItemAnchor.position === BEFORE &&
					createItemAnchor.beforeId === childId
				) {
					childPrevItemOffset += itemHeight;
					currentOffset += itemHeight;
				}
				result.push({
					id: String(childId),
					position: childPrevItemOffset + BASE_LEVEL_ITEM_HEIGHT() / 2,
					height: BASE_LEVEL_ITEM_HEIGHT(),
				});

				childPrevItemOffset += BASE_LEVEL_ITEM_HEIGHT();
			});
		}
		prevItemOffset = currentOffset + totalHeight;
	});
	return result;
};

const parsePositions = (items: ItemOffset[]): Hash<number> =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items.reduce<Record<string, any>>((acc, { id, position }: ItemOffset) => {
		acc[id] = position;
		return acc;
	}, {});

const parseHeight = (items: ItemOffset[], headerItemsHeight: number): number => {
	let itemsHeight = 0;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items.reduce<Record<string, any>>((acc, { height }: ItemOffset) => {
		itemsHeight += height;
		return acc;
	}, {});

	return itemsHeight + headerItemsHeight + BASE_HEADER_HEIGHT;
};

export const getItemOffsetPositions = createSelector(getItemsOffsets, parsePositions);

export const getItemsHeight = createSelector(
	getItemsOffsets,
	(headerItemsHeight: number) => headerItemsHeight,
	parseHeight,
);
