import { createSelector } from '@atlassian/jira-common-selectors/src/index.tsx';
import { MAX_LOZENGE_WIDTH } from '@atlassian/jira-software-roadmap-image-export/src/export/common/constants.tsx';

import type { HeaderItem } from '@atlassian/timeline-table/common/types/header';
import type { Item, ExpandedItems } from '@atlassian/timeline-table/common/types/item';

import { getCloudId } from '../app/selectors.tsx';
import { getToday, getProjectId } from '../configuration/selectors.tsx';
import { getListWidth } from '../settings/selectors.tsx';
import { getExpandedItems } from '../ui/table/selectors.tsx';
import { getItems, getTableHeight, getItemHeight, getTableHeaderItems } from './table/index.tsx';

const additionalSpaceForAssigneeLozenge = MAX_LOZENGE_WIDTH;

export const getShareTableProps = createSelector(
	getItems,
	getExpandedItems,
	getToday,
	getListWidth,
	getTableHeight,
	getItemHeight,
	getTableHeaderItems,
	(
		items: Item[],
		expandedItems: ExpandedItems,
		today: number,
		listWidth: number,
		listHeight: number,
		itemHeight: number,
		headerItems: HeaderItem[],
	) => ({
		items,
		expandedItems,
		today,
		listWidth: listWidth + additionalSpaceForAssigneeLozenge,
		listHeight,
		itemHeight,
		headerItems,
	}),
);

export const getBaseAri = createSelector(getCloudId, (cloudId: string) =>
	cloudId != null && cloudId !== '' ? `ari:cloud:jira:${cloudId}` : null,
);

export const getRoadmapAri = createSelector(
	getBaseAri,
	getProjectId,
	(baseAri: string, projectId: string) => {
		if (baseAri == null || baseAri === '' || projectId == null) {
			return '';
		}
		return `${baseAri}:project/${projectId}`;
	},
);
