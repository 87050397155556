import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';

import type { TimelineMode } from '@atlassian/timeline-table/common/types/timeline';
import { getTimelineMode } from '../../../state/selectors/filters/index.tsx';
import type { State } from '../../../state/types.tsx';
import { setTimelineMode } from '../../../state/ui/filters/actions.tsx';

export default defineSimpleStorage<State, TimelineMode | null>(
	() => 'timelineMode',
	(timelineMode: TimelineMode | null) =>
		timelineMode === null ? [] : [setTimelineMode(timelineMode)],
	(state) => getTimelineMode(state),
);
