import { fg } from '@atlassian/jira-feature-gating';
import { MILLISECONDS_PER_DAY } from '@atlassian/jira-software-roadmap-model/src/datetime/index.tsx';

// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import {
	getDateFromTimelinePosition as getDateFromTimelinePositionOld,
	getTimelinePositionFromDate as getTimelinePositionFromDateOld,
} from '@atlassian/jira-software-roadmap-timeline-table/src/common/utils/timeline.tsx';
import type { TimelineDuration } from '@atlassian/timeline-table/common/types/timeline';
import {
	getDateFromTimelinePosition,
	getTimelinePositionFromDate,
} from '@atlassian/timeline-table/common/utils/timeline';

import { DUE_DATE_OFFSET } from '../../constants/date.tsx';

// The left position of the bar on the timeline, in pixels (aligns to start of day)
export const getBarLeftPosition = (
	startDate: number,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
) =>
	fg('switch_timeline_table_imports')
		? getTimelinePositionFromDate(timelineDuration, timelineWidth, startDate)
		: getTimelinePositionFromDateOld(timelineDuration, timelineWidth, startDate);

// The right position of the bar on the timeline, in pixels (aligns to end of day)
export const getBarRightPosition = (
	dueDate: number,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
) => {
	const endOfDueDate = dueDate + DUE_DATE_OFFSET;
	const leftPosition = fg('switch_timeline_table_imports')
		? getTimelinePositionFromDate(timelineDuration, timelineWidth, endOfDueDate)
		: getTimelinePositionFromDateOld(timelineDuration, timelineWidth, endOfDueDate);
	return timelineWidth - leftPosition;
};

export const getClosestDay = (date: number): number =>
	Math.round(date / MILLISECONDS_PER_DAY) * MILLISECONDS_PER_DAY;

export const getStartDate = (
	leftPosition: number,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
): number => {
	const startDate = fg('switch_timeline_table_imports')
		? getDateFromTimelinePosition(timelineDuration, timelineWidth, leftPosition)
		: getDateFromTimelinePositionOld(timelineDuration, timelineWidth, leftPosition);
	return getClosestDay(startDate);
};

export const getDueDate = (
	rightPosition: number,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
): number => {
	const position = fg('switch_timeline_table_imports')
		? getDateFromTimelinePosition(timelineDuration, timelineWidth, timelineWidth - rightPosition)
		: getDateFromTimelinePositionOld(
				timelineDuration,
				timelineWidth,
				timelineWidth - rightPosition,
			);
	const dueDate = position - DUE_DATE_OFFSET;
	return getClosestDay(dueDate);
};

export const getIsBarVisible = (
	barLeftPosition: number,
	barRightPosition: number,
	timelineWidth: number,
): boolean => {
	const isBeforeTimeline = barLeftPosition < 0 && barRightPosition >= timelineWidth;
	const isAfterTimeline = barLeftPosition >= timelineWidth && barRightPosition < 0;

	return !isBeforeTimeline && !isAfterTimeline;
};
