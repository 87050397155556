import { type FocusEvent, type KeyboardEvent, useCallback, useRef } from 'react';

import { useFocusMarshal, useIsNavigationEnabled, useIsNavigationPrevented } from '../index';

const isElementInGrid = (grid: HTMLElement, target: HTMLElement) => {
	let current: HTMLElement | null = target;

	while (current) {
		if (current === grid) {
			return true;
		}
		current = current.parentElement;
	}

	return false;
};

/* This hook is fairly simple, but integral in our grid navigation functionality.
 * It delegates events at the root of the grid and maps them to the relevant marshal action.
 */
export const useGridContainer = () => {
	const element = useRef<HTMLElement | null>(null);

	const { enableNavigation, disableNavigation, navigateCell, resetRovingCell } = useFocusMarshal();

	const isNavigationEnabled = useIsNavigationEnabled();
	const isNavigationPrevented = useIsNavigationPrevented();

	const setGridRef = useCallback((ref: HTMLElement | null) => {
		element.current = ref;
	}, []);

	// Keyboard interactions from https://www.w3.org/WAI/ARIA/apg/patterns/grid
	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			const { key, ctrlKey } = event;

			if (isNavigationPrevented) {
				return;
			}

			switch (key) {
				case 'Enter':
					if (isNavigationEnabled) {
						event.preventDefault();
						disableNavigation();
					}
					break;
				case 'Escape':
					if (!isNavigationEnabled) {
						event.preventDefault();
						enableNavigation();
					}
					break;
				case 'ArrowLeft':
				case 'ArrowRight':
				case 'ArrowUp':
				case 'ArrowDown':
				case 'PageUp':
				case 'PageDown':
				case 'Home':
				case 'End': {
					if (isNavigationEnabled) {
						event.preventDefault();
						navigateCell(key, { ctrlKey });
					}
					break;
				}
				default:
					break;
			}
		},
		[isNavigationPrevented, isNavigationEnabled, disableNavigation, enableNavigation, navigateCell],
	);

	const onKeyUp = useCallback(
		({ key }: KeyboardEvent) => {
			if (isNavigationPrevented) {
				return;
			}

			switch (key) {
				/* We don't stop keyboard users from tabbing, but we want to promote the semantic
				 * behaviour by automatically entering edit mode. This should NOT be done on key
				 * down, or the focus trap will be activated too early.
				 */
				case 'Tab': {
					if (isNavigationEnabled) {
						disableNavigation({ autofocus: false });
					}
					break;
				}
				default:
					break;
			}
		},
		[isNavigationEnabled, isNavigationPrevented, disableNavigation],
	);

	const onBlur = useCallback(
		({ relatedTarget }: FocusEvent) => {
			if (isNavigationPrevented) {
				return;
			}

			if (
				element.current &&
				relatedTarget instanceof HTMLElement &&
				!isElementInGrid(element.current, relatedTarget)
			) {
				resetRovingCell();
			}
		},
		[isNavigationPrevented, resetRovingCell],
	);

	return { ref: setGridRef, onKeyDown, onKeyUp, onBlur };
};

/* This hook is fairly simple, but integral in our grid navigation functionality.
 * It delegates events at the root of the grid and maps them to the relevant marshal action.
 */
export const useGridContainerOld = () => {
	const { focusCellOld, blurCell, navigateCell } = useFocusMarshal();

	const onFocus = useCallback(
		(event: FocusEvent) => {
			if (event.target instanceof HTMLElement) {
				focusCellOld(event.target);
			}
		},
		[focusCellOld],
	);

	const onBlur = useCallback(
		(event: FocusEvent) => {
			if (event.target instanceof HTMLElement) {
				blurCell(event.target);
			}
		},
		[blurCell],
	);

	// Keyboard interactions from https://www.w3.org/WAI/ARIA/apg/patterns/grid
	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			const { key, ctrlKey } = event;

			switch (key) {
				case 'ArrowLeft':
				case 'ArrowRight':
				case 'ArrowUp':
				case 'ArrowDown':
				case 'PageUp':
				case 'PageDown':
				case 'Home':
				case 'End': {
					event.preventDefault();
					navigateCell(key, { ctrlKey });
					break;
				}
				default:
					break;
			}
		},
		[navigateCell],
	);

	return { onKeyDown, onFocus, onBlur };
};
