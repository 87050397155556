import React from 'react';
import noop from 'lodash/noop';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

import type { APIProps } from '@atlassian/timeline-table';
import {
	DEFAULT_ITEM_HEIGHT,
	DEFAULT_LIST_WIDTH,
} from '@atlassian/timeline-table/common/constants';
import type TimelineTableType from '@atlassian/timeline-table';

const LazyTimelineTable = lazyAfterPaint<typeof TimelineTableType>(
	() =>
		import(
			/* webpackChunkName: "async-standard-roadmap-timeline-table" */ '@atlassian/timeline-table'
		),
);

const AsyncTimelineTable = (props: APIProps) => {
	return (
		<JSErrorBoundary
			id="async-standard-roadmap-timeline-table"
			packageName="jiraSoftwareRoadmapStandard"
			fallback="flag"
		>
			<Placeholder name="standard-roadmap-timeline-table" fallback={null}>
				<LazyTimelineTable {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);
};

AsyncTimelineTable.defaultProps = {
	isVirtualiseEnabled: false,
	isCreateLastEnabled: true,
	isCreateSiblingEnabled: true,
	isCreateChildEnabled: true,
	isDragEnabled: true,
	isSelectEnabled: true,
	isMultiSelectEnabled: false,
	isResizeEnabled: true,
	isHighlightToday: true,
	isHideScrollbar: false,
	items: [],
	headerItems: [],
	expandedItems: {},
	renderers: {},
	marshalOptions: {},
	createItemAnchor: undefined,
	activeItemId: undefined,
	selectedItemIds: [],
	itemHeight: DEFAULT_ITEM_HEIGHT,
	listWidth: DEFAULT_LIST_WIDTH(),
	DndLongTaskMetric: undefined,
	onItemSelect: noop,
	onItemMultiSelect: noop,
	onItemActivate: noop,
	onItemDrop: noop,
	onCreateClick: noop,
	onItemExpandChanged: noop,
	onSetListWidth: noop,
	ariaRowCount: undefined,
	ariaLabel: undefined,
	ariaReadOnly: undefined,
	listItemMenuOptions: {
		overrideLabels: {},
		customSections: [],
	},
};

export default AsyncTimelineTable;
