import {
	DEPENDENCIES_OVERLAY_CLASS_NAME,
	DEPENDENCY_LINE_CLASS_NAME,
} from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/constants.tsx';
import { DEPENDENCIES_DROP_TARGET_CLASS_NAME } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/dependency.tsx';

import type {
	MarshalOptions,
	CustomMarshalOptions,
	OverrideMarshalOptions,
} from '@atlassian/timeline-table/common/context/side-effect-marshal/types';
import {
	dependenciesOverlayHiddenStyles,
	dependenciesDropTargetStyles,
	dependencyLinesHiddenStyles,
} from './styles.tsx';

/**
 * Marshal action overrides
 */

const onRowDragStart: OverrideMarshalOptions['onRowDragStart'] = ({ styleAPI }) => {
	styleAPI.insertStyle(DEPENDENCIES_OVERLAY_CLASS_NAME, dependenciesOverlayHiddenStyles);
	styleAPI.insertStyle(DEPENDENCY_LINE_CLASS_NAME, dependencyLinesHiddenStyles);
};

const onRowDragEnd: OverrideMarshalOptions['onRowDragEnd'] = ({ styleAPI }) => {
	styleAPI.removeStyle(DEPENDENCIES_OVERLAY_CLASS_NAME);
	styleAPI.removeStyle(DEPENDENCY_LINE_CLASS_NAME);
};

/**
 * Custom marshal actions
 */

const onDependencyDragStart: CustomMarshalOptions['onDependencyDragStart'] = ({ styleAPI }) => {
	styleAPI.insertStyle(DEPENDENCIES_DROP_TARGET_CLASS_NAME, dependenciesDropTargetStyles);
};

const onDependencyDragEnd: CustomMarshalOptions['onDependencyDragEnd'] = ({ styleAPI }) => {
	styleAPI.removeStyle(DEPENDENCIES_DROP_TARGET_CLASS_NAME);
};

const onBarInteractionStart: CustomMarshalOptions['onBarInteractionStart'] = ({ styleAPI }) => {
	styleAPI.insertStyle(DEPENDENCIES_OVERLAY_CLASS_NAME, dependenciesOverlayHiddenStyles);
	styleAPI.insertStyle(DEPENDENCY_LINE_CLASS_NAME, dependencyLinesHiddenStyles);
};

const onBarInteractionEnd: CustomMarshalOptions['onBarInteractionEnd'] = ({ styleAPI }) => {
	styleAPI.removeStyle(DEPENDENCIES_OVERLAY_CLASS_NAME);
	styleAPI.removeStyle(DEPENDENCY_LINE_CLASS_NAME);
};

export const marshalOptions: MarshalOptions = {
	customActions: {
		onDependencyDragStart,
		onDependencyDragEnd,
		onBarInteractionStart,
		onBarInteractionEnd,
	},
	overrideActions: {
		onRowDragStart,
		onRowDragEnd,
	},
};
