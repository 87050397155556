import type { TimelineTableInteractionMetricType } from '@atlassian/timeline-table/common/types/interaction-metrics';

export const DEPENDENCY_DRAG_AND_DROP = 'dependencyDnd' as const;
export const ISSUE_VIEW_RESIZE_DRAG_AND_DROP = 'issueViewResizeDnd' as const;
export const CHART_BAR_RESIZE = 'chartBarResize' as const;
export const PERSIST_ITEM = 'persistItem' as const;
export const EXPORT_IMAGE_GENERATED = 'exportImageGenerated' as const;
export const ISSUE_UPDATED = 'issueUpdated' as const;
export const DEPENDENCY_ADDED = 'dependencyAdded' as const;
export const DEPENDENCY_REMOVED = 'dependencyRemoved' as const;
export const BULK_ISSUES_SCHEDULED = 'bulkIssuesScheduled' as const;
export const RESOLVE_HEALTHCHECK = 'resolveHealthcheck' as const;
export const CRITICAL_DATA = 'criticalData' as const;
export const VERSION_DETAILS = 'versionDetails' as const;
export const SPRINT_DETAILS = 'sprintDetails' as const;
export const FILTER_CONFIGURATION = 'filterConfiguration' as const;
export const DERIVE_FIELDS = 'deriveFields' as const;
export const FILTER_ROADMAP_ITEMS = 'filterRoadmapItems' as const;
export const GET_ISSUE_IDS = 'getIssueIds' as const;
export const GET_ITEMS_BY_IDS = 'getItemsByIds' as const;
export const GET_SUBTASKS = 'getSubtasks' as const;
export const LOAD_HEALTHCHECK_ISSUE_TYPES = 'healthcheckIssueTypes' as const;
export const JQL_FILTERS_REAPPLY = 'jqlFiltersReapply' as const;
export const GET_GOALS = 'getGoals' as const;

export type InteractionMetricType =
	| typeof DEPENDENCY_DRAG_AND_DROP
	| typeof ISSUE_VIEW_RESIZE_DRAG_AND_DROP
	| typeof CHART_BAR_RESIZE
	| typeof EXPORT_IMAGE_GENERATED
	| typeof JQL_FILTERS_REAPPLY
	| TimelineTableInteractionMetricType;

export type MutationMetricType =
	| typeof PERSIST_ITEM
	| typeof ISSUE_UPDATED
	| typeof DEPENDENCY_ADDED
	| typeof DEPENDENCY_REMOVED
	| typeof BULK_ISSUES_SCHEDULED
	| typeof RESOLVE_HEALTHCHECK;

export type QueryMetricType =
	| typeof CRITICAL_DATA
	| typeof VERSION_DETAILS
	| typeof SPRINT_DETAILS
	| typeof FILTER_CONFIGURATION
	| typeof DERIVE_FIELDS
	| typeof FILTER_ROADMAP_ITEMS
	| typeof GET_ISSUE_IDS
	| typeof GET_ITEMS_BY_IDS
	| typeof GET_SUBTASKS
	| typeof LOAD_HEALTHCHECK_ISSUE_TYPES
	| typeof GET_GOALS;
