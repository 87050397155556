/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { BASE_BAR_HEIGHT } from '../../../../common/constants/chart-item.tsx';
import { CHART_OVERLAY_Z_INDEX } from '../../../../common/constants/z-index.tsx';
import type { OnChartItemUpdate } from '../../../../common/types/chart-item.tsx';
import type { Interval } from '../../../../common/types/interval.tsx';
import {
	useTargetIntervalId,
	useResetChartInteractionState,
} from '../../../../controllers/table-providers/chart-item-interaction/main.tsx';

const DROPZONE_BACKGROUND = token('color.background.accent.blue.bolder', `${B100}`);
const INTERVAL_DROPZONE_WIDTH_EXPANSION = 2;

const dropZoneStyles = css({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${BASE_BAR_HEIGHT}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_OVERLAY_Z_INDEX,
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: `${DROPZONE_BACKGROUND}`,
	backgroundColor: DROPZONE_BACKGROUND,
	transition:
		'opacity 0.2s ease-in-out, border-width 0.2s ease-in-out, left 0.2s ease-in-out, right 0.2s ease-in-out',
});

export type Props = {
	id: string;
	left: number;
	right: number;
	intervals: ReadonlyArray<Interval>;
	onClick?: OnChartItemUpdate;
};

export const IntervalDropZone = ({
	id,
	left,
	right,
	intervals,
	onClick: onClickDropZone,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [targetIntervalId] = useTargetIntervalId(intervals);
	const { resetChartInteractionState } = useResetChartInteractionState();

	const onClick = () => {
		if (onClickDropZone && targetIntervalId !== undefined) {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropzone',
			});

			onClickDropZone(id, { intervalId: targetIntervalId }, analyticsEvent);
			resetChartInteractionState();
			fireUIAnalytics(analyticsEvent, 'intervalDropzone');
		}
	};

	return (
		<div
			css={dropZoneStyles}
			style={{
				left: `${
					targetIntervalId !== undefined ? left - INTERVAL_DROPZONE_WIDTH_EXPANSION : left + 1
				}px`,
				right: `${
					targetIntervalId !== undefined ? right - INTERVAL_DROPZONE_WIDTH_EXPANSION : right + 1
				}px`,
				opacity: targetIntervalId !== undefined ? 0.38 : 0.13,
				cursor: targetIntervalId !== undefined ? 'pointer' : 'default',
				/* border width is used to expand the dropzone vertically */
				borderWidth: targetIntervalId !== undefined ? `${INTERVAL_DROPZONE_WIDTH_EXPANSION}px` : 0,
			}}
			onClick={onClick}
			onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space') && onClick()}
			role="presentation"
		/>
	);
};

export default memo<Props>(IntervalDropZone);
