import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	// Section Titles
	general: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.general',
		defaultMessage: 'General',
		description: 'Label for general legend section',
	},
	issueBars: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.issue-bars',
		defaultMessage: 'Issue bars',
		description: 'Label for issue bars legend section',
	},
	releases: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.releases',
		defaultMessage: 'Releases',
		description: 'Label for releases legend section',
	},
	// General
	sprints: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.sprints',
		defaultMessage: 'Sprints',
		description: 'Label for sprints icon',
	},
	dependency: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.dependency',
		defaultMessage: 'Dependency',
		description: 'Label for dependency icon',
	},
	rollup: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.rollup',
		defaultMessage: 'Inferred value (roll-up)',
		description: 'Label for rollup value icon',
	},
	// Issue bars
	noEndDate: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.no-end-date',
		defaultMessage: 'No end date',
		description: 'Label for no end date icon',
	},
	noStartDate: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.no-start-date',
		defaultMessage: 'No start date',
		description: 'Label for no start date icon',
	},
	// Releases
	released: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.released',
		defaultMessage: 'Released',
		description: 'Label for released icon',
	},
	unreleased: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.unreleased',
		defaultMessage: 'Unreleased',
		description: 'Label for unreleased icon',
	},
	overdue: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.overdue',
		defaultMessage: 'Overdue',
		description: 'Label for overdue icon',
	},
	issueBarsIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.legend.issue-bars-issue-term-refresh',
		defaultMessage: 'Issue bars',
		description: 'Label for issue bars legend section',
	},
}) as {
	general: MessageDescriptor;
	issueBars: MessageDescriptor;
	issueBarsIssueTermRefresh: MessageDescriptor;
	releases: MessageDescriptor;
	sprints: MessageDescriptor;
	dependency: MessageDescriptor;
	rollup: MessageDescriptor;
	noEndDate: MessageDescriptor;
	noStartDate: MessageDescriptor;
	released: MessageDescriptor;
	unreleased: MessageDescriptor;
	overdue: MessageDescriptor;
};
