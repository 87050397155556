import React, { useEffect, useCallback, useState, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ChevronLeftCircleIconOld from '@atlaskit/icon/glyph/chevron-left-circle';
import ChevronRightCircleIconOld from '@atlaskit/icon/glyph/chevron-right-circle';
import ChevronLeftCircleIcon from '@atlaskit/icon/utility/chevron-left';
import ChevronRightCircleIcon from '@atlaskit/icon/utility/chevron-right';
import { N40, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import WidthExpander from './width-expander/index.tsx';

const renderItem = (key: string, component: ReactNode, showDivider: boolean) => (
	<React.Fragment key={key}>
		{showDivider && <Divider data-testid="aais-timeline-toolbar.ui.container.divider" />}
		{component}
	</React.Fragment>
);

const Toolbar = ({ items, isCollapsible = true }: Props) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(true);

	const toggleIsExpanded = useCallback(() => {
		if (isCollapsible) setIsExpanded(!isExpanded);
	}, [isExpanded, isCollapsible, setIsExpanded]);

	useEffect(() => {
		if (!isExpanded && !isCollapsible) setIsExpanded(true);
	}, [isExpanded, isCollapsible, setIsExpanded]);

	const renderContent = () =>
		items
			.filter(({ isVisible = true }) => isVisible)
			.map(({ id, element, showDivider }, index) => {
				return renderItem(
					id,
					element,
					isVisualRefreshEnabled() && showDivider !== undefined
						? showDivider
						: isCollapsible || index !== 0,
				);
			});

	const { formatMessage } = useIntl();

	const COLLAPSED_WIDTH = isVisualRefreshEnabled() ? '0px' : '32px';

	return (
		<TimelineToolbarWrapper>
			<WidthExpander isExpanded={isExpanded} collapsedWidth={COLLAPSED_WIDTH}>
				<TimelineToolbarContainer>
					{!isVisualRefreshEnabled() && isCollapsible && (
						<Button
							testId="aais-timeline-toolbar.ui.container.expand-button"
							appearance="subtle"
							onClick={toggleIsExpanded}
							iconBefore={
								isExpanded ? (
									<ChevronRightCircleIconOld label={formatMessage(messages.expandButtonLabel)} />
								) : (
									<ChevronLeftCircleIconOld label={formatMessage(messages.collapseButtonLabel)} />
								)
							}
							aria-expanded={isExpanded}
							{...(fg('platform_button_item-add-ufo-metrics')
								? { interactionName: 'timeline-toolbar-expand-clicked' }
								: {})}
						/>
					)}
					{renderContent()}
				</TimelineToolbarContainer>
			</WidthExpander>
			{isVisualRefreshEnabled() && (
				<IconButton
					label={
						isExpanded
							? formatMessage(messages.expandButtonLabel)
							: formatMessage(messages.collapseButtonLabel)
					}
					aria-expanded={isExpanded}
					testId="aais-timeline-toolbar.ui.container.expand-button"
					appearance="subtle"
					onClick={toggleIsExpanded}
					icon={() =>
						isExpanded ? (
							<ChevronRightCircleIcon label="" color="currentColor" />
						) : (
							<ChevronLeftCircleIcon label="" color="currentColor" />
						)
					}
					{...(fg('platform_button_item-add-ufo-metrics')
						? { interactionName: 'timeline-toolbar-expand-clicked' }
						: {})}
				/>
			)}
		</TimelineToolbarWrapper>
	);
};

export default Toolbar;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Divider = styled.div({
	backgroundColor: `${token('color.border', N40)}`,
	height: '24px',
	width: '1px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimelineToolbarWrapper = styled.div({
	backgroundColor: `${token('elevation.surface.raised', N0)}`,
	padding: token('space.050', '4px'),
	display: 'flex',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: `${token('color.border', N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimelineToolbarContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'>:is(div, button)': {
		flexShrink: 0,
	},
});
