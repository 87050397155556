import { fg } from '@atlaskit/platform-feature-flags';

import type { CellMeta } from './types';
import { coordToString } from './utils';

export class CellMap extends Map<HTMLElement, CellMeta> {
	private isDirty = false;

	private elementLookup = new Map<string, HTMLElement>();

	private rowBoundaries = new Map<number, { min: number; max: number }>();

	private columnBoundaries = new Map<number, { min: number; max: number }>();

	private globalMin: [column: number, row: number] = [0, 0];

	private globalMax: [column: number, row: number] = [0, 0];

	// ================= //
	// === Mutations === //
	// ================= //

	set(key: HTMLElement, value: CellMeta) {
		this.isDirty = true;
		return super.set(key, value);
	}

	delete(key: HTMLElement) {
		this.isDirty = true;
		return super.delete(key);
	}

	clear() {
		this.isDirty = true;
		return super.clear();
	}

	setIsDirty(value: boolean) {
		this.isDirty = value;
	}

	// ============= //
	// === Reads === //
	// ============= //

	getBefore(key: HTMLElement) {
		const cell = this.get(key);
		if (!cell) {
			return;
		}

		this.prepareCoordinates();

		const [column, row] = cell.getCoordinates();
		return this.getClosestByColumn(column - 1, row);
	}

	getAfter(key: HTMLElement) {
		const cell = this.get(key);
		if (!cell) {
			return;
		}

		this.prepareCoordinates();

		const [column, row] = cell.getCoordinates();
		return this.getClosestByColumn(column + 1, row);
	}

	getAbove(key: HTMLElement, offset = 1) {
		const cell = this.get(key);
		if (!cell) {
			return;
		}

		this.prepareCoordinates();

		const [column, row] = cell.getCoordinates();
		return this.getClosestByRow(column, row - offset);
	}

	getBelow(key: HTMLElement, offset = 1) {
		const cell = this.get(key);
		if (!cell) {
			return;
		}

		this.prepareCoordinates();

		const [column, row] = cell.getCoordinates();
		return this.getClosestByRow(column, row + offset);
	}

	getStart(key: HTMLElement, isGlobal = false) {
		this.prepareCoordinates();
		const cell = this.get(key);

		if (isGlobal || !cell) {
			return this.elementLookup.get(coordToString(...this.globalMin));
		}

		const [, row] = cell.getCoordinates();
		const columnBoundary = this.columnBoundaries.get(row);
		if (columnBoundary) {
			return this.getClosestByColumn(columnBoundary.min, row);
		}
	}

	getEnd(key: HTMLElement, isGlobal = false) {
		this.prepareCoordinates();
		const cell = this.get(key);

		if (isGlobal || !cell) {
			return this.elementLookup.get(coordToString(...this.globalMax));
		}

		const [, row] = cell.getCoordinates();
		const columnBoundary = this.columnBoundaries.get(row);
		if (columnBoundary) {
			return this.getClosestByColumn(columnBoundary.max, row);
		}
	}

	getClosestStartOrEnd(key: HTMLElement) {
		const cell = this.get(key);
		if (!cell) {
			return;
		}

		this.prepareCoordinates();

		const [column, row] = cell.getCoordinates();
		const rowBoundary = this.rowBoundaries.get(column);

		if (rowBoundary) {
			const isClosestToStart = Math.abs(row - rowBoundary.min) <= Math.abs(row - rowBoundary.max);
			return isClosestToStart ? this.getStart(key, true) : this.getEnd(key, true);
		}
	}

	// ================= //
	// === Utilities === //
	// ================= //

	private clean() {
		this.elementLookup.clear();
		this.rowBoundaries.clear();
		this.columnBoundaries.clear();
		this.globalMin = [0, 0];
		this.globalMax = [0, 0];

		this.isDirty = false;
	}

	/* We only want to evaluate the co-ordinates just-in-time for a cell operation. This reduces
	 * the need to reconcile our "data state" and the DOM state, which can get spooky real fast.
	 * Here we also prepare some data structures to improve time complexity of successive operations.
	 */
	private prepareCoordinates() {
		if (!this.isDirty) {
			return;
		}
		this.clean();

		for (const [key, value] of this) {
			const [column, row] = value.getCoordinates();

			// Store reverse lookup internally for easier retrieval of cell element
			this.elementLookup.set(coordToString(column, row), key);

			const rowBoundary = this.rowBoundaries.get(column);
			const columnBoundary = this.columnBoundaries.get(row);

			if (rowBoundary === undefined) {
				this.rowBoundaries.set(column, { min: row, max: row });
			} else {
				const min = Math.min(rowBoundary.min, row);
				const max = Math.max(rowBoundary.max, row);
				this.rowBoundaries.set(column, { min, max });
			}

			if (columnBoundary === undefined) {
				this.columnBoundaries.set(row, { min: column, max: column });
			} else {
				const min = Math.min(columnBoundary.min, column);
				const max = Math.max(columnBoundary.max, column);
				this.columnBoundaries.set(row, { min, max });
			}

			if (row <= this.globalMin[1]) {
				// Grab fresh value in case it was set above in this iteration of the loop
				const updatedColumnBoundary = this.columnBoundaries.get(row);
				const minColumn = updatedColumnBoundary?.min ?? this.globalMin[0];
				this.globalMin = [minColumn, row];
			}

			if (row >= this.globalMax[1]) {
				// Grab fresh value in case it was set above in this iteration of the loop
				const updatedColumnBoundary = this.columnBoundaries.get(row);
				const maxColumn = updatedColumnBoundary?.max ?? this.globalMax[0];
				this.globalMax = [maxColumn, row];
			}
		}
	}

	/* Used to get the cell before or after another cell in the horizontal axis.
	 * !! NOTE: We cannot assume that rows are contiguous for the same column without greatly degrading the user experience.
	 * This is because there are gaps in row navigation, e.g. going down from [3,0] might actually result in [2,1] and not [3,1]
	 */
	private getClosestByRow(column: number, row: number): HTMLElement | undefined {
		// Happy path - the closest coordinates are just those passed in
		const happyCell = this.elementLookup.get(coordToString(column, row));
		if (happyCell) {
			return happyCell;
		}

		const columnBoundary = this.columnBoundaries.get(row);
		const rowBoundary = this.rowBoundaries.get(column);

		// Can't find the cell, so check whether...
		// 1. The row exists, it's just not within the column boundaries of that row. Constrain the column to the boundaries.
		if (columnBoundary) {
			const clampedColumn = columnBoundary.max;
			return this.elementLookup.get(coordToString(clampedColumn, row));
		}

		// 2. The row does not exist, due to being outside the row boundaries for that column
		if (rowBoundary && (row > rowBoundary.max || row < rowBoundary.min)) {
			const isClosestToStart = Math.abs(row - rowBoundary.min) <= Math.abs(row - rowBoundary.max);
			const closestRow = isClosestToStart ? rowBoundary.min : rowBoundary.max;
			return this.elementLookup.get(coordToString(column, closestRow));
		}

		// 3. The row does not exist, but its within the row boundaries for that column. Get the next closest row.
		if (
			rowBoundary &&
			row <= rowBoundary.max &&
			row >= rowBoundary.min &&
			fg('timeline_grid_navigation_m2')
		) {
			const possibleRows = [];

			for (const [, value] of this) {
				const coordinates = value.getCoordinates();
				if (coordinates[0] === column && coordinates[1] > row) {
					possibleRows.push(coordinates[1]);
				}
			}

			// Only works for integers, but is faster than Array.prototype.sort even with the need to clone
			const fastSortArray = Uint32Array.from(possibleRows).sort();
			return this.elementLookup.get(coordToString(column, fastSortArray[0]));
		}

		return undefined;
	}

	/* Used to get the cell before or after another cell in the horizontal axis.
	 * 1. Happy path - the closest coordinates are just those passed in.
	 * 2. However the column could exceed or precede the boundaries for that row, in which case we do nothing.
	 * !! NOTE: This assumes that the columns are contiguous for the same row
	 */
	private getClosestByColumn(column: number, row: number): HTMLElement | undefined {
		return this.elementLookup.get(coordToString(column, row));
	}
}
