/** @jsx jsx */
import React, { type SyntheticEvent, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { B200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { withDragObserver } from '@atlassian/jira-drag-observer/src/ui/index.tsx';
import { zIndex } from '../../../../../common/constants.tsx';

type Props = {
	isDragging: boolean;
	onDragStart: (nativeEvent: SyntheticEvent) => void;
	onDrag: (nativeEvent: SyntheticEvent) => void;
	onDragEnd: (nativeEvent: SyntheticEvent) => void;
	children?: ReactNode;
};

const ResizeHandler = ({ isDragging, children, ...props }: Props) => (
	<div css={[resizeHandlerStyles, isDragging ? draggingStyles : staticStyles]} {...props}>
		{children}
	</div>
);

export default withDragObserver(ResizeHandler);

const resizeHandlerStyles = css({
	position: 'absolute',
	top: 0,
	right: token('space.negative.025', '-2px'),
	width: token('space.100', '8px'),
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST_RESIZER,
	transition: 'opacity 0.1s ease',
	pointerEvents: 'all',
	cursor: 'ew-resize',
	'&::after': {
		position: 'absolute',
		display: 'block',
		content: '',
		top: 0,
		right: token('space.025', '2px'),
		width: token('space.025', '2px'),
		height: '100%',
		backgroundColor: token('color.border.selected', B200),
		pointerEvents: 'none',
	},
});

const staticStyles = css({
	opacity: 0,
	'&:hover': {
		opacity: 1,
	},
});

const draggingStyles = css({
	opacity: 1,
});
