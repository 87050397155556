import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics/src/index.tsx';
import { dndReporterWithSlo } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-dnd.tsx';
import { genericReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-generic.tsx';
import { scrollReporterWithSlo } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-scroll-slo.tsx';
import type { ObservationData } from '@atlassian/jira-common-long-task-metrics/src/types.tsx';
import {
	LIST_DRAG_AND_DROP,
	LIST_RESIZE_DRAG_AND_DROP,
	CHILD_LIST_DRAG_AND_DROP,
	SCROLLED,
} from '@atlassian/timeline-table/common/constants';
import {
	CHART_BAR_RESIZE,
	DEPENDENCY_DRAG_AND_DROP,
	ISSUE_VIEW_RESIZE_DRAG_AND_DROP,
} from './common/constants.tsx';

export type LongTaskMetrics = {
	start: <T>(type: T) => void;
	stop: <T>(
		type: T,
		extraProps?: {
			[key: string]: boolean;
		},
	) => void;
};

const dndReporterWithSloAndExtraProps =
	(props: { [key: string]: boolean }) =>
	(
		observationData: ObservationData,
		extraProps: {
			[key: string]: boolean;
		},
		stopTimestamp: DOMHighResTimeStamp,
	) =>
		dndReporterWithSlo({
			framerateThreshold: 20,
			blocktimeThreshold: 100,
		})(observationData, { ...extraProps, ...props }, stopTimestamp);

const slowDndReporterWithSloAndExtraProps =
	(props: { [key: string]: boolean }) =>
	(
		observationData: ObservationData,
		extraProps: {
			[key: string]: boolean;
		},
		stopTimestamp: DOMHighResTimeStamp,
	) =>
		dndReporterWithSlo({
			framerateThreshold: 16,
			blocktimeThreshold: 100,
		})(observationData, { ...extraProps, ...props }, stopTimestamp);

const scrollReporterWithSloAndExtraProps =
	(props: { [key: string]: boolean }) =>
	(
		observationData: ObservationData,
		extraProps: {
			[key: string]: boolean;
		},
	) =>
		scrollReporterWithSlo({
			framerateThreshold: 20,
			blocktimeThreshold: 100,
		})(observationData, { ...extraProps, ...props });

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (appId: string): LongTaskMetrics => ({
	start: <T,>(type: T) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		getLongTasksMetrics(type as string).start(appId);
	},

	stop: <T,>(
		type: T,
		extraProps: {
			[key: string]: boolean;
		} = {},
	) => {
		let reporter: // eslint-disable-next-line @typescript-eslint/no-explicit-any
		| ((observationData: ObservationData, featureFlags?: Record<any, any>) => void)
			| ((
					observationData: ObservationData,
					extraProps: { [key: string]: boolean },
					stopTimestamp: DOMHighResTimeStamp,
			  ) => void)
			| ((observationData: ObservationData, extraProps: { [key: string]: boolean }) => void) =
			genericReporter;

		switch (type) {
			case CHART_BAR_RESIZE:
			case LIST_DRAG_AND_DROP:
			case CHILD_LIST_DRAG_AND_DROP:
			case LIST_RESIZE_DRAG_AND_DROP:
			case DEPENDENCY_DRAG_AND_DROP:
				reporter = dndReporterWithSloAndExtraProps(extraProps);
				break;
			case ISSUE_VIEW_RESIZE_DRAG_AND_DROP:
				reporter = slowDndReporterWithSloAndExtraProps(extraProps);
				break;
			case SCROLLED:
				reporter = scrollReporterWithSloAndExtraProps(extraProps);
				break;
			default:
				reporter = genericReporter;
				break;
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		getLongTasksMetrics(type as string).stop(reporter);
	},
});
