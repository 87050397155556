import { createSelector } from 'reselect';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Hash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import {
	BASE_LEVEL,
	PARENT_LEVEL,
	isBaseLevel,
} from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import type { Sprint } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type { InteractiveChartItem } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/controllers/table-providers/chart-item-interaction/common/types.tsx';

import type { ExpandedItems } from '@atlassian/timeline-table/common/types/item';
import {
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
	hasActiveOrFutureSprint,
} from '../../../../state/configuration/selectors.tsx';
import {
	getIssueParentIdHash,
	getIssueCreationTransitionHash,
} from '../../../../state/entities/issues/selectors.tsx';
import { getSanitisedIssueSprintsHash } from '../../../../state/selectors/sprint/index.tsx';
import type { ChartData } from '../../../../state/selectors/table/chart-pure/common/types.tsx';
import { getChartDataHash } from '../../../../state/selectors/table/index.tsx';
import { getExpandedItems, getSelectedItemIds } from '../../../../state/ui/table/selectors.tsx';

// When the new item shape is stable, we can move the isInCreation check to renderChartItemContent #ROAD-4572
const isItemInteractive = ({
	level,
	hasSprint,
	isSprintsPlanning,
	isChildIssuePlanningEnabled,
	isInCreation,
}: {
	level: number;
	hasSprint: boolean;
	isSprintsPlanning: boolean;
	isChildIssuePlanningEnabled: boolean;
	isInCreation: boolean;
}) => {
	/**
	 * Child items should not be interactive when child issue planning is disabled
	 * or when no active/future sprints on a Scrum roadmap
	 */
	const isChildDateBasedPlanning =
		isChildIssuePlanningEnabled && ((isSprintsPlanning && hasSprint) || !isSprintsPlanning);
	if (!isInCreation && (level === PARENT_LEVEL || isChildDateBasedPlanning)) {
		return true;
	}

	return false;
};

export const getInteractiveChartItems = createSelector(
	getChartDataHash,
	getIssueParentIdHash,
	getExpandedItems,
	getSanitisedIssueSprintsHash,
	getIssueCreationTransitionHash,
	getIsSprintsPlanningEnabled,
	getIsChildIssuePlanningEnabled,
	hasActiveOrFutureSprint,
	(
		chartDataHash: Hash<ChartData>,
		parentIdHash: Hash<IssueId | undefined>,
		expandedItems: ExpandedItems,
		issueSprintsHash: Hash<Sprint[]>,
		issueIsInCreationHash: Hash<boolean | undefined>,
		isSprintsPlanning: boolean,
		isChildIssuePlanningEnabled: boolean,
		hasSprint: boolean,
	): InteractiveChartItem[] => {
		const items: Array<InteractiveChartItem> = [];

		Object.keys(chartDataHash).forEach((issueId) => {
			const { dueDate, startDate, placeholder, startDateType, dueDateType } =
				chartDataHash[issueId];
			const parentId = parentIdHash[issueId];

			if (parentId === undefined || (parentId && expandedItems[parentId])) {
				const sprints = issueSprintsHash[issueId];
				const level = parentId !== undefined ? BASE_LEVEL : PARENT_LEVEL;
				const intervalId = isBaseLevel(level) ? sprints[0]?.id.toString() : undefined;
				const isInCreation = !!issueIsInCreationHash[issueId];

				if (
					isItemInteractive({
						level,
						hasSprint,
						isSprintsPlanning,
						isChildIssuePlanningEnabled,
						isInCreation,
					})
				) {
					items.push({
						id: issueId,
						dueDate,
						startDate,
						placeholder,
						parentId,
						level,
						intervalId,
						startDateType,
						dueDateType,
					});
				}
			}
		});

		return items;
	},
);

export const getSelectedItemIdsMemoized = createSelector(
	getSelectedItemIds,
	(selectedItemIds: IssueId[]): string[] => selectedItemIds.map(String),
);
