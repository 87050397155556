import { useCallback, useRef, useState } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useTimelineState as useTimelineStateOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useViewport as useViewportOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/context/index.tsx';
import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';
import { useViewport } from '@atlassian/timeline-table/common/context/viewport/context';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { ENTITY_FIELD_KEYS } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { DEPENDENCY_DRAG_ITEM_ID } from '../../../../../../../../common/constants/dependency.tsx';
import type {
	DragHandleType,
	OnDropDependency,
	DependencyDragData,
} from '../../../../../../../../common/types/dependency.tsx';
import { getRelativeHandlePosition } from '../../../../../../../../common/utils/dependency/handler-position.tsx';

type Props = {
	id: string;
	type: DragHandleType;
	leftPosition: number;
	rightPosition: number;
	getBarBoundingClientRect: () => DOMRect | undefined;
	onDrop: OnDropDependency;
};

const useDependenciesDrag = ({
	id,
	type,
	getBarBoundingClientRect,
	leftPosition,
	rightPosition,
}: Props): [{ isDragging: boolean }, (el: HTMLElement | null) => void, () => void] => {
	const cleanupDragAndDrop = useRef<null | (() => void)>(null);
	const [dragState, setDragState] = useState({ isDragging: false });
	const [{ timelineWidth }] = fg('switch_timeline_table_imports')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useTimelineState()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useTimelineStateOld();
	const { requestViewportBoundingClient, requestViewportScrollBounding, setViewportScroll } = fg(
		'switch_timeline_table_imports',
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useViewport()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useViewportOld();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const makeDraggable = useCallback(
		(dragElement: HTMLElement | null) => {
			const el = dragElement;
			if (el === null) {
				return;
			}

			cleanupDragAndDrop.current = combine(
				draggable({
					element: el,
					getInitialData: () =>
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						({
							type: DEPENDENCY_DRAG_ITEM_ID,
							id,
							handleType: type,
							barRect: getBarBoundingClientRect(),
							leftPosition: getRelativeHandlePosition(
								type,
								timelineWidth,
								leftPosition,
								rightPosition,
							),
							viewportBoundingRect: requestViewportBoundingClient(),
							initialScrollBoundingRect: requestViewportScrollBounding(),
							requestViewportScrollBounding,
							setViewportScroll,
						}) as DependencyDragData,
					onGenerateDragPreview: ({ source }) => {
						const { element: draggedElement } = source;
						// hide the native drag preview
						draggedElement.style.opacity = '0';
					},
					onDragStart: ({ source }) => {
						const { element: draggedElement } = source;
						draggedElement.style.opacity = '1';
						setDragState({ isDragging: true });
						if (fg('one_event_rules_them_all_fg')) {
							getUpdateAnalyticsFlowHelper().fireAnalyticsStart(ENTITY_FIELD_KEYS.ISSUE_LINKS, {
								analytics: createAnalyticsEvent({}),
								attributes: {
									fieldType: ENTITY_FIELD_KEYS.ISSUE_LINKS,
									isDragEditing: true,
								},
							});
						}
					},
					onDrop: () => {
						setDragState({ isDragging: false });
					},
				}),
			);
		},
		[
			id,
			type,
			getBarBoundingClientRect,
			timelineWidth,
			leftPosition,
			rightPosition,
			requestViewportBoundingClient,
			requestViewportScrollBounding,
			setViewportScroll,
			createAnalyticsEvent,
		],
	);

	const cleanupDraggable = useCallback(() => {
		cleanupDragAndDrop.current?.();
	}, []);

	return [dragState, makeDraggable, cleanupDraggable];
};

export { useDependenciesDrag };
