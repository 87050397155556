import { createContext, useContext } from 'react';

import noop from 'lodash/noop';

import type { ViewportContextProps } from '../common/types';

import { DEFAULT_BOUNDING_CLIENT, DEFAULT_SCROLL_BOUNDING } from './constants';

const ViewportContext = createContext<ViewportContextProps>({
	requestViewportBoundingClient: () => DEFAULT_BOUNDING_CLIENT,
	requestViewportScrollBounding: () => DEFAULT_SCROLL_BOUNDING,
	listenToViewportScroll: noop,
	stopListeningToViewportScroll: noop,
	setViewportScroll: noop,
});

const useViewport = () => useContext(ViewportContext);

export default ViewportContext;
export { useViewport };
