import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/mergeAll';
import { batchActions, type BatchAction } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import logs from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { addRoadmapDependencyMutation$data } from '@atlassian/jira-relay/src/__generated__/addRoadmapDependencyMutation.graphql';
import { addDependencyOnServer } from '@atlassian/jira-software-roadmap-services/src/issues/add-roadmap-dependency.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { ENTITY_FIELD_KEYS } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { DEPENDENCY_ADD_ERROR } from '../../../model/dependencies/index.tsx';
import { getSourceARI } from '../../../state/app/selectors.tsx';
import { areDependenciesSupported } from '../../../state/configuration/selectors.tsx';
import {
	type CreateDependencyAction,
	CREATE_DEPENDENCY,
	addDependency,
	deleteDependency,
} from '../../../state/entities/issues/actions.tsx';
import {
	getIssueDependenciesHash,
	getIssueKeyHash,
	getIssueKeyById,
	getIssueLevel,
} from '../../../state/entities/issues/selectors.tsx';
import {
	hiddenDependencyCreated,
	dependenciesUnavailable,
	dependenciesPermission,
	aggError,
	dependencyCycleCreation,
	dependencyDuplication,
	type HiddenDependencyCreatedAction,
} from '../../../state/flags/actions.tsx';
import { isDependenciesVisible } from '../../../state/selectors/filters/index.tsx';
import type { State } from '../../../state/types.tsx';
import { reapplyJQLFilters } from '../../../state/ui/filters/actions.tsx';
import { isDependencyCyclic } from '../../../utils/dependencies/detect-dependency-cycle.tsx';
import { isPermissionError } from '../../common/get-error.tsx';
import { onHandleAggErrors } from '../../common/handle-agg-errors.tsx';
import type { StateEpic } from '../../common/types.tsx';
import refreshIssuePage from '../refresh-issue-page.tsx';
import { getSafeDependencyInput } from './common/index.tsx';

export const isDependencyDuplicated = (state: State, action: CreateDependencyAction) => {
	const existingDependencies = getIssueDependenciesHash(state);
	const { dependency, dependee } = action.payload;

	return existingDependencies[dependee]?.includes(dependency);
};

const fireAnalytics = (state: State, action: CreateDependencyAction, analyticsAction: string) => {
	const clonedEvent = action.meta.analyticsEvent.clone();
	const { dependency, dependee } = action.payload;

	if (clonedEvent) {
		fireTrackAnalytics(clonedEvent, `issueDependency ${analyticsAction}`, {
			dependeeLevel: getIssueLevel(state, dependee),
			dependencyLevel: getIssueLevel(state, dependency),
		});
	}
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<CreateDependencyAction>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(CREATE_DEPENDENCY)
		.map((action: CreateDependencyAction) => {
			const state = store.getState();
			const payload = {
				dependee: getIssueKeyById(state, action.payload.dependee),
				dependency: getIssueKeyById(state, action.payload.dependency),
			};

			if (!payload.dependency || !payload.dependee || payload.dependency === payload.dependee) {
				return Observable.empty<never>();
			}

			const isCyclic = isDependencyCyclic({
				dependenciesHash: getIssueDependenciesHash(state),
				dependency: action.payload.dependency,
				dependee: action.payload.dependee,
			});
			const isDuplicate = isDependencyDuplicated(state, action);
			const isSupported = areDependenciesSupported(store.getState());

			if (fg('one_event_rules_them_all_fg')) {
				if (isCyclic || isDuplicate || !isSupported) {
					getUpdateAnalyticsFlowHelper().endSession(ENTITY_FIELD_KEYS.ISSUE_LINKS);
				} else {
					getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(ENTITY_FIELD_KEYS.ISSUE_LINKS, {
						analytics: action.meta.analyticsEvent,
						attributes: {
							fieldType: ENTITY_FIELD_KEYS.ISSUE_LINKS,
							isDragEditing: action.payload.isDragged || false,
						},
					});
				}
			}

			if (isCyclic) {
				logs.safeErrorWithoutCustomerData(
					'roadmap.standard.issues-add-dependency',
					'Dependency cycle exceeds supported depth.',
				);
				return Observable.of(dependencyCycleCreation(payload));
			}

			if (isDuplicate) {
				return Observable.of(dependencyDuplication(payload));
			}

			if (!isSupported) {
				fireAnalytics(store.getState(), action, 'addFailed');
				return Observable.of(dependenciesUnavailable());
			}

			const sourceARI = getSourceARI(state);
			const inputObservable = getSafeDependencyInput(state, action.payload);

			const longRunning = inputObservable.mergeMap((input) => {
				const { dependee, dependency } = input;

				const addDependencyOnBackendPromise = addDependencyOnServer({
					sourceARI,
					input: { dependee, dependency },
				}).mergeMap(
					(
						data: addRoadmapDependencyMutation$data,
					): Observable<BatchAction | HiddenDependencyCreatedAction> => {
						const addRoadmapDependencyData = data?.roadmaps?.addRoadmapDependency;
						const errors = addRoadmapDependencyData?.errors;
						const success = addRoadmapDependencyData?.success;

						if (success === false && errors?.length) {
							if (isPermissionError(errors)) {
								const actions = [
									deleteDependency(action.payload),
									dependenciesPermission({
										type: DEPENDENCY_ADD_ERROR,
										dependeeKey: getIssueKeyHash(state)[action.payload.dependee],
										dependencyKey: getIssueKeyHash(state)[action.payload.dependency],
									}),
								];

								return Observable.of(batchActions(actions));
							}
							return onHandleAggErrors(!!addRoadmapDependencyData, errors).map((aggErrorAction) =>
								batchActions([deleteDependency(action.payload), aggErrorAction]),
							);
						}

						refreshIssuePage(state, getIssueKeyById(state, action.payload.dependee));
						refreshIssuePage(state, getIssueKeyById(state, action.payload.dependency));

						fireAnalytics(store.getState(), action, 'added');

						if (isDependenciesVisible(store.getState())) {
							return Observable.empty<never>();
						}

						return Observable.of(hiddenDependencyCreated());
					},
				);

				return addDependencyOnBackendPromise.catch(() => {
					const actions = [deleteDependency(action.payload), aggError()];
					return Observable.of(batchActions(actions));
				});
			});
			return Observable.concat(
				Observable.of(addDependency(action.payload)),
				longRunning,
				fg('jsw-roadmap-state-change-based-issue-hidden-flags')
					? Observable.empty()
					: Observable.of(reapplyJQLFilters()),
			);
		})
		.mergeAll()) as StateEpic;
