import { QUARTERS, MONTHS, WEEKS } from '@atlassian/timeline-table/common/constants';

// Limit in number of MONTHS for selected date range
export const DATE_RANGE_LIMIT = {
	[QUARTERS]: 18,
	[MONTHS]: 12,
	[WEEKS]: 12,
} as const;

export const MAX_LOZENGE_WIDTH = 72;
