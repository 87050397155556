/** @jsx jsx */
import { forwardRef } from 'react';
import { css, jsx } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { N0, Y300, N50A, DN0, Y200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { borderRadius, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { CHART_BAR_Z_INDEX } from '../../../../../common/constants/z-index.tsx';

const containerStyles = css({
	position: 'absolute',
	display: 'block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	transform: `translate(100%) translateX(${gridSize}px)`,
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_BAR_Z_INDEX,
});

const contentWrapperStyles = css({
	display: 'flex',
	width: '240px',
	marginBottom: token('space.050', '4px'),
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	backgroundColor: token('elevation.surface.overlay', N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: token('elevation.shadow.overlay', `0 ${borderRadius}px ${borderRadius}px 0 ${N50A}`),
	color: token('color.text', DN0),
});

const tooltipTextStyles = css({
	padding: `${token('space.050', '4px')} 0`,
	font: token('font.heading.xxsmall'),
	marginLeft: token('space.100', '8px'),
});

const iconContainerStyles = css({
	color: token('color.text.warning', Y200),
	display: 'flex',
});

type Props = {
	right: number;
	warnings: MessageDescriptor[];
};

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	<span css={iconContainerStyles} ref={ref} {...props} />
));

const RoadmapBarWarnings = ({ right, warnings }: Props) => {
	const { formatMessage } = useIntl();

	// FIXME: Chronos should fix that by extracting the forwarded component

	const TooltipComponent = forwardRef<HTMLDivElement, TooltipPrimitiveProps>(
		(tooltipProps, ref) => <TooltipPrimitive {...tooltipProps} ref={ref} />,
	);

	const tooltipContent = warnings.map((errorMessage) => (
		<div css={contentWrapperStyles} key={`${errorMessage.id}`}>
			<WarningIcon
				color={token('color.icon.warning', Y300)}
				LEGACY_size="medium"
				label=""
				spacing="spacious"
			/>
			<div css={tooltipTextStyles}>{formatMessage(errorMessage)}</div>
		</div>
	));

	return (
		<div css={containerStyles} style={{ right: `${right}px` }}>
			<Tooltip
				component={TooltipComponent}
				content={tooltipContent}
				position="bottom-start"
				delay={0}
				hideTooltipOnClick
				tag={TooltipTag}
			>
				<WarningIcon color={token('color.icon.warning', Y300)} label="" spacing="spacious" />
			</Tooltip>
		</div>
	);
};

export { RoadmapBarWarnings };
