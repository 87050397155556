/** @jsx jsx */
import React, { type ReactElement, type ComponentType } from 'react';
import { css, jsx } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';

import { DEPENDENCY_DRAG_ITEM_ID } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/dependency.tsx';
import { RoadmapChartDragLayer } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-overlay/drag-layer/index.tsx';
import type { DragLayerMeta } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-overlay/drag-layer/types.tsx';
import { RoadmapChartOverlay } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-overlay/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useHeaderState as useHeaderStateOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/header/index.tsx';

import { zIndex } from '@atlassian/timeline-table/common/constants';

import { useHeaderState as useHeaderStateNew } from '@atlassian/timeline-table/common/context/header';

import DependencyLinePreview from './dependency-line-preview/index.tsx';
import ChartOverlayButton from './overlay-button/index.tsx';
import type { DependencyDragItem } from './types.tsx';
import { getPreviewProps } from './utils.tsx';

type Props = {
	isEditEnabled: boolean;
	DndLongTaskMetric: ComponentType<{
		isDragging: boolean;
	}>;
};

const renderOverlayButton = () => <ChartOverlayButton />;

const ChartOverlay = ({ isEditEnabled, DndLongTaskMetric }: Props) => {
	const useHeaderState = fg('switch_timeline_table_imports')
		? useHeaderStateNew
		: useHeaderStateOld;
	const [{ headerHeight }] = useHeaderState();
	const renderPreview = (props: DragLayerMeta<DependencyDragItem>): ReactElement | null => {
		const previewProps = getPreviewProps(props);

		return previewProps ? <DependencyLinePreview {...previewProps} /> : null;
	};

	const renderChartDragLayer = () =>
		isEditEnabled ? (
			<RoadmapChartDragLayer
				type={DEPENDENCY_DRAG_ITEM_ID}
				renderPreview={renderPreview}
				DndLongTaskMetric={DndLongTaskMetric}
			/>
		) : null;

	return (
		<div css={dragLayerContainerStyles} style={{ top: headerHeight }}>
			<RoadmapChartOverlay
				renderChartDragLayer={renderChartDragLayer}
				renderOverlayButton={renderOverlayButton}
			/>
		</div>
	);
};

export default ChartOverlay;

const dragLayerContainerStyles = css({
	position: 'absolute',
	right: 0,
	bottom: 0,
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.DRAGGABLE,
});
