import { TIMELINE_ID } from '@atlassian/timeline-table/common/constants';

type ScrollTo = {
	left?: number;
	top?: number;
};

/* Components from all over the node tree have functionality that involves scrolling the timeline.
 * React has not been able to simply fulfil our requirements, so back to vanilla JS we go.
 */

const scrollTo = ({ left, top }: ScrollTo) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const timelineElem: HTMLElement | null = document.getElementById(TIMELINE_ID);

	if (timelineElem !== null) {
		if (left !== undefined) {
			timelineElem.scrollLeft = left;
		}

		if (top !== undefined) {
			timelineElem.scrollTop = top;
		}
	}
};

const getScrollLeft = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const timelineElem: HTMLElement | null = document.getElementById(TIMELINE_ID);

	if (timelineElem !== null) {
		return timelineElem.scrollLeft;
	}

	return 0;
};

const getVisibleWidth = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const timelineElem: HTMLElement | null = document.getElementById(TIMELINE_ID);

	if (timelineElem !== null) {
		return timelineElem.clientWidth;
	}

	return 0;
};

export const timelineScroller = {
	scrollTo,
	getScrollLeft,
	getVisibleWidth,
} as const;

export const useTimelineScroller = () => timelineScroller;

export type { ScrollTo };
