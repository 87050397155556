import React, { type ComponentPropsWithoutRef } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { ROADMAP_PACKAGE_NAME } from '../../../../constants.tsx';
import Skeleton from './skeleton/index.tsx';
import type ViewSettingsMenuType from './view.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyViewSettingsMenu = lazy<typeof ViewSettingsMenuType>(
	() => import(/* webpackChunkName: "async-standard-roadmap-view-settings-menu" */ './view.tsx'),
);

const ViewSettingsMenu = (props: ComponentPropsWithoutRef<typeof ViewSettingsMenuType>) => (
	<JSErrorBoundary
		id="async-standard-roadmap-view-settings-menu"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="standard-roadmap-view-settings-menu" fallback={<Skeleton />}>
			<LazyViewSettingsMenu {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default ViewSettingsMenu;
