import React from 'react';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import {
	RELEASES_HEADER_ID,
	SPRINTS_HEADER_ID,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/index.tsx';
import { RoadmapListHeaderRow } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/list-header-item/index.tsx';

import { DEFAULT_HEADER_ROW_ID } from '@atlassian/timeline-table/common/constants';

import type { HeaderItem } from '@atlassian/timeline-table/common/types/header';
import messages from './messages.tsx';

const ListHeaderItem = ({ id }: HeaderItem) => {
	const { formatMessage } = useIntl();

	switch (id) {
		case RELEASES_HEADER_ID:
			return <RoadmapListHeaderRow title={formatMessage(messages.releases)} />;
		case SPRINTS_HEADER_ID:
			return <RoadmapListHeaderRow title={formatMessage(messages.sprints)} />;
		case DEFAULT_HEADER_ROW_ID:
			return <VisuallyHidden>{formatMessage(messages.empty)}</VisuallyHidden>;
		default:
			return null;
	}
};

export const renderListHeaderItem = ({ id }: HeaderItem) => <ListHeaderItem id={id} />;
