import React, { memo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useTimelineState as useTimelineStateOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';
import type { OnChartItemUpdate } from '../../../common/types/chart-item.tsx';
import type { Interval, IntervalGroupPositions } from '../../../common/types/interval.tsx';
import { useIntervalDropZoneData } from '../../../controllers/table-providers/chart-item-interaction/main.tsx';
import IntervalDropZone from './drop-zone/index.tsx';
import { getActiveOrFutureIntervalGroups } from './utils.tsx';

type Props = {
	intervals: ReadonlyArray<Interval>;
	id: string;
	onClick?: OnChartItemUpdate;
};

type VisibleProps = {
	intervals: ReadonlyArray<Interval>;
	currentIntervalId: string | undefined;
	id: string;
	onClick?: OnChartItemUpdate;
};

const VisibleIntervalDropZones = ({ id, intervals, currentIntervalId, onClick }: VisibleProps) => {
	const [{ timelineDuration, timelineWidth }] = fg('switch_timeline_table_imports')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useTimelineState()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useTimelineStateOld();

	const activeOrFutureGroups = getActiveOrFutureIntervalGroups({
		intervals,
		currentIntervalId,
		timelineDuration,
		timelineWidth,
	});

	return (
		<>
			{activeOrFutureGroups.map((intervalGroup: IntervalGroupPositions, index) => (
				<IntervalDropZone
					id={id}
					key={index}
					left={Math.max(intervalGroup.left, intervalGroup.targetIntervalLeft)}
					right={Math.max(intervalGroup.right, intervalGroup.targetIntervalRight)}
					intervals={intervalGroup.intervals}
					onClick={onClick}
				/>
			))}
		</>
	);
};

// Tiny wrapper to minimise work when drop zones aren't visible.
export const IntervalDropZones = ({ id, intervals, onClick }: Props) => {
	// this is where we show/hide the dropzones
	const [{ isVisible, currentIntervalId }] = useIntervalDropZoneData(id);

	return isVisible ? (
		<VisibleIntervalDropZones
			id={id}
			intervals={intervals}
			currentIntervalId={currentIntervalId}
			onClick={onClick}
		/>
	) : null;
};

export default memo<Props>(IntervalDropZones);
