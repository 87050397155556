export const statusCodesNotImpactingReliability = [400, 404, 401, 403, 408, 426, 429, 414, 303];
export const statusCodesToSkipForSentry = [503];
export const errorMessagesToSkipForSentry = ['The underlying service call timed out'];
export const errorMessagesNotImpactingReliability = [
	'Failed to fetch',
	'NetworkError when attempting to fetch resource.',
	'The operation was aborted.',
	'Auth category: UNAUTHENTICATED is not allowed in service roadmaps',
	'Failed to retrieve data via roadmapCriticalDataFromRoadmapService. Underlying error: Error: Failed to fetch',
];
