/** @jsx jsx */
import React, {
	useEffect,
	useState,
	memo,
	useCallback,
	type MouseEvent,
	type FocusEvent,
	type KeyboardEvent,
} from 'react';
import { css, jsx } from '@compiled/react';
import Popup, { type ContentProps, type TriggerProps } from '@atlaskit/popup';
import { Text, xcss, Pressable, Box } from '@atlaskit/primitives';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { token } from '@atlaskit/tokens';
import type { TriggerProps as TooltipProps } from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useFocusMarshal as useFocusMarshalOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/side-effect-marshal/focus-marshal/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useViewport as useViewportOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/context/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { isEnterOrSpaceKey as isEnterOrSpaceKeyOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/utils/events.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { HEADER_CONTENT_HEIGHT } from '@atlassian/timeline-table/common/constants';
import { useFocusMarshal } from '@atlassian/timeline-table/common/context/side-effect-marshal/focus-marshal';
import { useViewport } from '@atlassian/timeline-table/common/context/viewport/context';
import { isEnterOrSpaceKey } from '@atlassian/timeline-table/common/utils/events';
import {
	BAR_GAP_WIDTH,
	EXPANDABLE_INDICATOR_WIDTH,
} from '../../../../common/constants/chart-header.tsx';
import { HEADER_BACKGROUND_COLOR } from '../../../../common/constants/color.tsx';
import { OVERLAY_FLYOUT_Z_INDEX } from '../../../../common/constants/z-index.tsx';
import type { IntervalGroupPositions, Interval } from '../../../../common/types/interval.tsx';
import { useIsHeaderModalActive } from '../../../../controllers/table-providers/chart-item-interaction/main.tsx';
import { ExpandableIndicator, ExpandableIndicatorOld } from './expandable-indicator/index.tsx';
import messages from './messages.tsx';
import { IntervalsTooltip } from './tooltip/index.tsx';
import { getDynamicMarkerStyles } from './utils/dynamic-styles.tsx';
import { getLabel, getSprintAriaLabel } from './utils/index.tsx';
import type { MarkerColorsOld } from './utils/types.tsx';

// Used in the context of the *local* layout to show expanded items over their sibling items
const EXPANDED_Z_INDEX = 1;

export type Props = {
	targetIntervalId?: string | undefined;
	intervalGroup: IntervalGroupPositions;
	/**
	 * render props method to show the Popup content when any key date been pressed
	 * @param selectedIntervals sprint intervals collection
	 * @returns
	 */
	renderPopupContent: (
		selectedIntervals: ReadonlyArray<Interval>,
		setInitialFocusRef?: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
	) => React.ReactNode;
};

/* A marker represents one or more intervals grouped together. The marker start is the start of the earliest interval,
 * and conversely, the marker end is the end of the latest interval.
 * Markers may be expandable or collapsible depending on its proximity to neighbouring markers.
 */
const BaseMarker = ({ targetIntervalId, intervalGroup, renderPopupContent }: Props) => {
	const { formatMessage } = useIntl();
	const { listenToViewportScroll, stopListeningToViewportScroll } = fg(
		'switch_timeline_table_imports',
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useViewport()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useViewportOld();
	const [isMouseEntered, setIsMouseEntered] = useState(false);
	const [isPopupActive, setIsPopupActive] = useState(false);
	const [{ isHeaderModalActive }] = useIsHeaderModalActive();

	const { shouldPreventNavigation } = fg('switch_timeline_table_imports')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useFocusMarshal()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useFocusMarshalOld();

	const openPopup = useCallback(() => {
		shouldPreventNavigation(true);
		setIsPopupActive(true);
	}, [shouldPreventNavigation]);

	const closePopup = useCallback(() => {
		shouldPreventNavigation(false);
		setIsPopupActive(false);
	}, [shouldPreventNavigation]);

	// Listen to user event scrolling the timeline table => Hide the popup on each time its been scrolled
	useEffect(() => {
		const onViewPortScrollHandler = () => {
			if (fg('timeline_grid_navigation_m2')) {
				closePopup();
			} else {
				setIsPopupActive(false);
			}
		};

		listenToViewportScroll(onViewPortScrollHandler);
		return function cleanup() {
			stopListeningToViewportScroll(onViewPortScrollHandler);
		};
	}, [closePopup, listenToViewportScroll, stopListeningToViewportScroll]);

	const { left, right, rightExpanded, intervals } = intervalGroup;
	const isExpandable = right !== rightExpanded;
	const isHighlighted = targetIntervalId !== undefined;
	const isExpanded = isExpandable && (isMouseEntered || isHighlighted);

	const { colors, borders, spacing, childVisibility } = getDynamicMarkerStyles({
		intervalGroup,
		isExpanded,
		isExpandable,
		isHighlighted,
	});

	const { isLabelVisible, isExpandIndicatorVisible } = childVisibility;
	const label = getLabel({ intervals, targetIntervalId, isLabelVisible });

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// ================= //
	// === CALLBACKS === //
	// ================= //

	const onMouseEnter = () => {
		if (!isPopupActive) {
			setIsMouseEntered(true);
		}
	};

	const onMouseLeave = () => {
		if (!isPopupActive) {
			setIsMouseEntered(false);
		}
	};

	const onClickContainer = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			traceUFOPress('timeline-interval-marker-clicked', event.timeStamp);
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(analyticsEvent, 'intervalsMarker', {
				numberOfMarker: intervals.length,
			});
			if (fg('timeline_grid_navigation_m2')) {
				openPopup();
			} else {
				setIsPopupActive(true);
			}
		},
		[createAnalyticsEvent, intervals.length, openPopup],
	);

	const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
		if (fg('switch_timeline_table_imports')) {
			if (isEnterOrSpaceKey(event)) {
				event.preventDefault();
				if (fg('timeline_grid_navigation_m2')) {
					openPopup();
				} else {
					setIsPopupActive(true);
				}
			}
		} else if (isEnterOrSpaceKeyOld(event)) {
			event.preventDefault();
			if (fg('timeline_grid_navigation_m2')) {
				openPopup();
			} else {
				setIsPopupActive(true);
			}
		}
	};

	// ============== //
	// === RENDER === //
	// ============== //

	const renderMarker = (
		{ ref: triggerRef, ...restTriggerProps }: TriggerProps,
		tooltipProps?: TooltipProps,
	) => {
		const { ref: tooltipRef, onFocus, onBlur, onClick, ...restTooltipProps } = tooltipProps ?? {};

		const handleOnFocus = (event: FocusEvent<HTMLElement>) => {
			onMouseEnter();
			onFocus?.(event);
		};

		const handleOnBlur = (event: FocusEvent<HTMLElement>) => {
			onMouseLeave();
			onBlur?.(event);
		};

		const handleOnClick = (event: MouseEvent<HTMLElement>) => {
			onClickContainer(event);
			onClick?.(event);
		};

		let borderRightStyles = 'none';
		if (isExpanded) {
			if (borders.border === 'none') {
				borderRightStyles = `${token('border.width', '1px')} solid ${colors.color}`;
			} else {
				borderRightStyles = borders.border;
			}
		}

		if (isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2')) {
			if (!isExpandable) {
				return (
					<Pressable
						{...restTriggerProps}
						{...restTooltipProps}
						ref={tooltipRef ? mergeRefs(triggerRef, tooltipRef) : triggerRef}
						aria-label={`${formatMessage(messages.sprintsCountLabel, { sprintsCount: intervals.length })} ${getSprintAriaLabel(intervals, formatMessage)}`}
						xcss={baseContainerStyles}
						style={{
							border:
								borders.border === 'none'
									? `${token('border.width', '1px')} solid ${colors.color}`
									: borders.border,
							borderRadius: borders.borderRadius,
							left: `${left}px`,
							right: `${right}px`,
						}}
						testId="roadmap.timeline-table-kit.ui.chart-header-item.intervals.marker.content"
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						onFocus={handleOnFocus}
						onBlur={handleOnBlur}
						onClick={handleOnClick}
						onKeyDown={handleKeyDown}
					>
						<Text maxLines={1} size="small">
							{label}
						</Text>
					</Pressable>
				);
			}

			return (
				<Pressable
					{...restTriggerProps}
					{...restTooltipProps}
					ref={tooltipRef ? mergeRefs(triggerRef, tooltipRef) : triggerRef}
					aria-label={`${formatMessage(messages.sprintsCountLabel, { sprintsCount: intervals.length })} ${getSprintAriaLabel(intervals, formatMessage)}`}
					xcss={[baseContainerStyles, expandableContainerStyles, transitionStyles]}
					style={{
						left: `${left}px`,
						right: `${isExpanded ? rightExpanded : right}px`,
						zIndex: isExpanded ? EXPANDED_Z_INDEX : 'auto',
					}}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
					onClick={handleOnClick}
					onKeyDown={handleKeyDown}
				>
					<Box
						testId="roadmap.timeline-table-kit.ui.chart-header-item.intervals.marker.content"
						xcss={expandableLabelWrapperStyles}
						backgroundColor="elevation.surface"
						style={{
							border:
								borders.border === 'none'
									? `${token('border.width', '1px')} solid ${colors.color}`
									: borders.border,
							borderRight: borderRightStyles,
							borderRadius: borders.borderRadius,
						}}
					>
						<Box as="span" paddingInline="space.050" xcss={overflowStyles}>
							{label}
						</Box>
					</Box>

					{isExpandIndicatorVisible && <ExpandableIndicator color={colors.color} />}
				</Pressable>
			);
		}

		if (!isExpandable) {
			return (
				// eslint-disable-next-line @atlaskit/design-system/no-html-button
				<span
					{...restTriggerProps}
					{...restTooltipProps}
					ref={tooltipRef ? mergeRefs(triggerRef, tooltipRef) : triggerRef}
					role="button"
					aria-label={`${formatMessage(messages.sprintsCountLabel, { sprintsCount: intervals.length })} ${getSprintAriaLabel(intervals, formatMessage)}`}
					css={[baseContainerStylesOld, overflowStylesOld]}
					style={{
						color: colors.color,
						// Type assertion for feature flag purposes
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						backgroundColor: (colors as MarkerColorsOld).backgroundColor,
						border: borders.border ?? 'none',
						borderRadius: borders.borderRadius,
						padding: spacing.padding,
						margin: spacing.margin,
						left: `${left}px`,
						right: `${right}px`,
					}}
					tabIndex={0}
					data-testid="roadmap.timeline-table-kit.ui.chart-header-item.intervals.marker.content"
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
					onClick={handleOnClick}
					onKeyDown={handleKeyDown}
				>
					{label}
				</span>
			);
		}

		return (
			// eslint-disable-next-line @atlaskit/design-system/no-html-button
			<div
				{...restTriggerProps}
				{...restTooltipProps}
				ref={tooltipRef ? mergeRefs(triggerRef, tooltipRef) : triggerRef}
				role="button"
				aria-label={`${formatMessage(messages.sprintsCountLabel, { sprintsCount: intervals.length })} ${getSprintAriaLabel(intervals, formatMessage)}`}
				css={[expandedContainerStylesOld, baseContainerStylesOld, transitionStylesOld]}
				style={{
					left: `${left}px`,
					right: `${isExpanded ? rightExpanded : right}px`,
					zIndex: isExpanded ? EXPANDED_Z_INDEX : 'auto',
					margin: spacing.margin,
				}}
				tabIndex={0}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				onClick={handleOnClick}
				onKeyDown={handleKeyDown}
			>
				<span
					data-testid="roadmap.timeline-table-kit.ui.chart-header-item.intervals.marker.content"
					css={[overflowStylesOld, isExpanded && expandableContentStylesOld]}
					style={{
						color: colors.color,
						// Type assertion for feature flag purposes
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						backgroundColor: (colors as MarkerColorsOld).backgroundColor,
						border: borders.border ?? 'none',
						borderRadius: borders.borderRadius,
						padding: spacing.padding,
						width: isExpandIndicatorVisible
							? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
								`calc(100% - ${EXPANDABLE_INDICATOR_WIDTH}px)`
							: '100%',
					}}
				>
					{label}
				</span>
				{isExpandIndicatorVisible && (
					// Type assertion for feature flag purposes
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					<ExpandableIndicatorOld backgroundColor={(colors as MarkerColorsOld).backgroundColor} />
				)}
			</div>
		);
	};

	/**
	 * Callback to render the user selected interval flyout popup content
	 */
	const renderSelectedIntervalPopupContent = useCallback(
		({ setInitialFocusRef }: ContentProps) => renderPopupContent(intervals, setInitialFocusRef),
		[intervals, renderPopupContent],
	);

	const renderPopupTrigger = (triggerProps: TriggerProps) => (
		<IntervalsTooltip intervals={intervals}>
			{(tooltipProps?: TooltipProps) => renderMarker(triggerProps, tooltipProps)}
		</IntervalsTooltip>
	);

	const onClosePopup = () => {
		if (!isHeaderModalActive) {
			if (isMouseEntered) {
				setIsMouseEntered(false);
			}
			if (fg('timeline_grid_navigation_m2')) {
				closePopup();
			} else {
				setIsPopupActive(false);
			}
		}
	};

	return (
		<Popup
			isOpen={isPopupActive}
			onClose={onClosePopup}
			placement="bottom-start"
			content={renderSelectedIntervalPopupContent}
			trigger={renderPopupTrigger}
			zIndex={OVERLAY_FLYOUT_Z_INDEX}
			autoFocus={false}
			role="dialog"
			label={formatMessage(messages.popupLabel)}
		/>
	);
};

const IntervalBaseMarker = memo<Props>(BaseMarker);
export { IntervalBaseMarker };

const baseContainerStylesOld = css({
	position: 'absolute',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${HEADER_CONTENT_HEIGHT}px`,
	minWidth: token('space.025', '2px'),
	textAlign: 'center',
	font: token('font.body.small'),
	lineHeight: token('space.150', '12px'),
	fontWeight: token('font.weight.bold'),
	cursor: 'pointer',
});

const baseContainerStyles = xcss({
	boxSizing: 'border-box',
	position: 'absolute',
	height: '20px',
	minWidth: 'space.025',
	backgroundColor: 'elevation.surface',
	color: 'color.text',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const expandableLabelWrapperStyles = xcss({
	height: '20px',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const overflowStylesOld = css({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const overflowStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	transition: 'right 0.2s ease-in-out',
	font: 'font.body.small',
});

const expandedContainerStylesOld = css({
	display: 'flex',
});

const expandableContainerStyles = xcss({
	marginRight: 'space.050',
	backgroundColor: 'color.background.neutral.subtle',
});

const transitionStylesOld = css({
	transition: 'right 0.2s ease-in-out',
});

const transitionStyles = xcss({
	transition: 'right 0.2s ease-in-out',
});

const expandableContentStylesOld = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	boxShadow: `inset -${BAR_GAP_WIDTH}px 0px 0px 0px ${HEADER_BACKGROUND_COLOR}`,
});
