/** @jsx jsx */
import React, { useState, useEffect, useCallback } from 'react';
import { css, keyframes, jsx } from '@compiled/react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useSideEffectMarshal as useSideEffectMarshalOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/side-effect-marshal/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useViewport as useViewportOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/context/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import Portal from '@atlassian/timeline-table/common/context/portal/portal';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import PortalOld from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/portal/portal/view.tsx';
import { useSideEffectMarshal } from '@atlassian/timeline-table/common/context/side-effect-marshal';
import { useViewport } from '@atlassian/timeline-table/common/context/viewport/context';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { ENTITY_FIELD_KEYS } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { TOP } from '../../../../../../../common/constants/index.tsx';
import type {
	DragHandleType,
	OnDropDependency,
	DependencyTheme,
} from '../../../../../../../common/types/dependency.tsx';
import { DependencyDragHandler } from '../../../../../../../common/ui/dependency/drag-handle/index.tsx';
import { getFixedHandlePosition } from '../../../../../../../common/utils/dependency/handler-position.tsx';
import { useDependenciesDrag } from './use-drag/index.tsx';

interface Props {
	id: string;
	type: DragHandleType;
	theme: DependencyTheme;
	leftPosition: number;
	rightPosition: number;
	isColorInverted?: boolean;
	getBarBoundingClientRect: () => ClientRect | undefined;
	onDrop: OnDropDependency;
	onDragEnd: () => void;
	onDragStart: (type: DragHandleType) => void;
}

const DependenciesDragHandlesOld = ({
	theme,
	id,
	type,
	leftPosition,
	rightPosition,
	isColorInverted,
	getBarBoundingClientRect,
	onDrop,
	onDragStart,
	onDragEnd,
}: Props) => {
	const [handlePosition, setHandlePosition] = useState<
		| {
				top: number;
				left: number;
		  }
		| undefined
	>(undefined);

	const { custom } = useSideEffectMarshalOld();
	const { listenToViewportScroll, stopListeningToViewportScroll } = useViewportOld();

	const [{ isDragging }, makeDraggable, cleanupDraggable] = useDependenciesDrag({
		id,
		type,
		leftPosition,
		rightPosition,
		getBarBoundingClientRect,
		onDrop,
	});

	const wasDragging = usePreviousWithInitial(isDragging);

	// Callbacks //

	const onUpdateBarRectPosition = useCallback(() => {
		const barClientRect = getBarBoundingClientRect();
		if (barClientRect) {
			const fixedHandlePosition = getFixedHandlePosition(type, barClientRect);
			setHandlePosition(fixedHandlePosition);
		}
	}, [type, getBarBoundingClientRect]);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onScrollEnd = useCallback(
		debounce(() => {
			onUpdateBarRectPosition();
		}, 100),
		[],
	);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onScroll = useCallback(
		throttle(() => {
			setHandlePosition(undefined);
			onScrollEnd();
		}, 100),
		[],
	);

	// Effects //

	useEffect(() => {
		listenToViewportScroll(onScroll);
		return () => {
			stopListeningToViewportScroll(onScroll);

			// drag connections will be unsubscribed if NULL was passed to drag handler.
			cleanupDraggable();
		};
	}, [onScroll, cleanupDraggable, listenToViewportScroll, stopListeningToViewportScroll]);

	useEffect(() => {
		if (!wasDragging && isDragging) {
			onDragStart(type);
			custom.onDependencyDragStart();
		} else if (wasDragging && !isDragging) {
			onDragEnd();
			custom.onDependencyDragEnd();
			if (fg('one_event_rules_them_all_fg')) {
				getUpdateAnalyticsFlowHelper().endSession(ENTITY_FIELD_KEYS.ISSUE_LINKS);
			}
		}
	}, [type, isDragging, wasDragging, onDragEnd, onDragStart, custom]);

	useEffect(() => {
		onUpdateBarRectPosition();
	}, [onUpdateBarRectPosition]);

	// Render //
	if (!handlePosition) {
		return null;
	}

	return (
		<PortalOld>
			{isVisualRefreshEnabled() && fg('visual-refresh_drop_1') ? (
				<div
					css={[
						dragHandleContainerStyles,
						type === TOP && dragHandleTopContainerStyles,
						type !== TOP && dragHandleBottomContainerStyles,
					]}
					style={{
						visibility: isDragging ? 'hidden' : 'visible',
						left: handlePosition.left,
						top: handlePosition.top,
					}}
					data-testid="roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.bar-content.dependency-handler.handle"
					ref={makeDraggable}
				>
					<div
						data-component-selector="timeline-data-dot"
						css={[
							dragHandleDotStyles,
							type === TOP && slideInTopStyles,
							type !== TOP && slideInBottomStyles,
						]}
						style={{
							backgroundColor: theme.handle.background,
						}}
					/>
					<div
						css={[
							dragHandleWrapperStyles,
							type === TOP && dragHandleTopWrapperStyles,
							type !== TOP && dragHandleBottomWrapperStyles,
						]}
						data-component-selector="timeline-drag-handler"
					>
						<DependencyDragHandler
							color={theme.icon}
							{...(fg('jsw_roadmaps_timeline-post-project-a11y-fix') ? { isColorInverted } : {})}
						/>
					</div>
				</div>
			) : (
				<div
					css={dragHandleContainerStylesOld}
					style={{
						visibility: isDragging ? 'hidden' : 'visible',
						left: handlePosition.left,
						top: handlePosition.top,
					}}
					data-testid="roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.bar-content.dependency-handler.handle"
					ref={makeDraggable}
				>
					<div
						data-component-selector="timeline-data-dot"
						css={[
							dragHandleDotStylesOld,

							type === TOP && slideInTopStylesOld,

							type !== TOP && slideInBottomStylesOld,
						]}
						style={{
							backgroundColor: theme.handle.background,
						}}
					/>
					<div css={dragHandleWrapperStyles} data-component-selector="timeline-drag-handler">
						<DependencyDragHandler
							color={theme.icon}
							{...(fg('jsw_roadmaps_timeline-post-project-a11y-fix') ? { isColorInverted } : {})}
						/>
					</div>
				</div>
			)}
		</PortalOld>
	);
};

const DependenciesDragHandlesNew = ({
	theme,
	id,
	type,
	leftPosition,
	rightPosition,
	isColorInverted,
	getBarBoundingClientRect,
	onDrop,
	onDragStart,
	onDragEnd,
}: Props) => {
	const [handlePosition, setHandlePosition] = useState<
		| {
				top: number;
				left: number;
		  }
		| undefined
	>(undefined);

	const { custom } = useSideEffectMarshal();
	const { listenToViewportScroll, stopListeningToViewportScroll } = useViewport();

	const [{ isDragging }, makeDraggable, cleanupDraggable] = useDependenciesDrag({
		id,
		type,
		leftPosition,
		rightPosition,
		getBarBoundingClientRect,
		onDrop,
	});

	const wasDragging = usePreviousWithInitial(isDragging);

	// Callbacks //

	const onUpdateBarRectPosition = useCallback(() => {
		const barClientRect = getBarBoundingClientRect();
		if (barClientRect) {
			const fixedHandlePosition = getFixedHandlePosition(type, barClientRect);
			setHandlePosition(fixedHandlePosition);
		}
	}, [type, getBarBoundingClientRect]);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onScrollEnd = useCallback(
		debounce(() => {
			onUpdateBarRectPosition();
		}, 100),
		[],
	);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onScroll = useCallback(
		throttle(() => {
			setHandlePosition(undefined);
			onScrollEnd();
		}, 100),
		[],
	);

	// Effects //

	useEffect(() => {
		listenToViewportScroll(onScroll);
		return () => {
			stopListeningToViewportScroll(onScroll);

			// drag connections will be unsubscribed if NULL was passed to drag handler.
			cleanupDraggable();
		};
	}, [onScroll, cleanupDraggable, listenToViewportScroll, stopListeningToViewportScroll]);

	useEffect(() => {
		if (!wasDragging && isDragging) {
			onDragStart(type);
			custom.onDependencyDragStart();
		} else if (wasDragging && !isDragging) {
			onDragEnd();
			custom.onDependencyDragEnd();
			if (fg('one_event_rules_them_all_fg')) {
				getUpdateAnalyticsFlowHelper().endSession(ENTITY_FIELD_KEYS.ISSUE_LINKS);
			}
		}
	}, [type, isDragging, wasDragging, onDragEnd, onDragStart, custom]);

	useEffect(() => {
		onUpdateBarRectPosition();
	}, [onUpdateBarRectPosition]);

	// Render //
	if (!handlePosition) {
		return null;
	}

	return (
		<Portal>
			{isVisualRefreshEnabled() && fg('visual-refresh_drop_1') ? (
				<div
					css={[
						dragHandleContainerStyles,
						type === TOP && dragHandleTopContainerStyles,
						type !== TOP && dragHandleBottomContainerStyles,
					]}
					style={{
						visibility: isDragging ? 'hidden' : 'visible',
						left: handlePosition.left,
						top: handlePosition.top,
					}}
					data-testid="roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.bar-content.dependency-handler.handle"
					ref={makeDraggable}
				>
					<div
						data-component-selector="timeline-data-dot"
						css={[
							dragHandleDotStyles,
							type === TOP && slideInTopStyles,
							type !== TOP && slideInBottomStyles,
						]}
						style={{
							backgroundColor: theme.handle.background,
						}}
					/>
					<div
						css={[
							dragHandleWrapperStyles,
							type === TOP && dragHandleTopWrapperStyles,
							type !== TOP && dragHandleBottomWrapperStyles,
						]}
						data-component-selector="timeline-drag-handler"
					>
						<DependencyDragHandler
							color={theme.icon}
							{...(fg('jsw_roadmaps_timeline-post-project-a11y-fix') ? { isColorInverted } : {})}
						/>
					</div>
				</div>
			) : (
				<div
					css={dragHandleContainerStylesOld}
					style={{
						visibility: isDragging ? 'hidden' : 'visible',
						left: handlePosition.left,
						top: handlePosition.top,
					}}
					data-testid="roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.bar-content.dependency-handler.handle"
					ref={makeDraggable}
				>
					<div
						data-component-selector="timeline-data-dot"
						css={[
							dragHandleDotStylesOld,

							type === TOP && slideInTopStylesOld,

							type !== TOP && slideInBottomStylesOld,
						]}
						style={{
							backgroundColor: theme.handle.background,
						}}
					/>
					<div css={dragHandleWrapperStyles} data-component-selector="timeline-drag-handler">
						<DependencyDragHandler
							color={theme.icon}
							{...(fg('jsw_roadmaps_timeline-post-project-a11y-fix') ? { isColorInverted } : {})}
						/>
					</div>
				</div>
			)}
		</Portal>
	);
};

const DependenciesDragHandles = componentWithFG(
	'switch_timeline_table_imports',
	DependenciesDragHandlesNew,
	DependenciesDragHandlesOld,
);

export default DependenciesDragHandles;

const slideInTop = keyframes({
	from: {
		opacity: 0,
		transform: 'translateY(100%)',
	},
	to: {
		opacity: 1,
		transform: 'translateY(0)',
	},
});

const slideInBottom = keyframes({
	from: {
		opacity: 0,
		transform: 'translateY(-100%)',
	},
	to: {
		opacity: 1,
		transform: 'translateY(0)',
	},
});

const SLIDE_OFFSET_OLD = 15;

const slideInTopOld = keyframes({
	from: {
		opacity: 0,
		transform: `translateY(${SLIDE_OFFSET_OLD}px)`,
	},
	to: {
		opacity: 1,
		transform: 'translateY(0)',
	},
});

const slideInBottomOld = keyframes({
	from: {
		opacity: 0,
		transform: `translateY(-${SLIDE_OFFSET_OLD}px)`,
	},
	to: {
		opacity: 1,
		transform: 'translateY(0)',
	},
});

const slideInTopStyles = css({
	animation: `${slideInTop} 0.4s`,
	margin: '10px 0 2px 0',
});

const slideInBottomStyles = css({
	animation: `${slideInBottom} 0.4s`,
	margin: '2px 0 10px 0',
});

const slideInTopStylesOld = css({
	animation: `${slideInTopOld} 0.4s`,
});

const slideInBottomStylesOld = css({
	animation: `${slideInBottomOld} 0.4s`,
});

const dragHandleDotStyles = css({
	width: '4px',
	height: '4px',
	borderRadius: '50%',
	cursor: 'pointer',
	transition: 'opacity 0.2s ease-in-out',
});

const dragHandleDotStylesOld = css({
	width: token('space.100', '8px'),
	height: token('space.100', '8px'),
	borderRadius: '50%',
	cursor: 'pointer',
	transition: 'opacity 0.2s ease-in-out',
});

const dragHandleWrapperStyles = css({
	position: 'absolute',
	top: token('space.025', '2px'),
	left: token('space.025', '2px'),
	cursor: 'pointer',
	opacity: 0,
	transition: 'opacity 0.2s ease-in-out',
});

const dragHandleTopWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-10px', // aligns top link icon with drag handle
});

const dragHandleBottomWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '10px', // aligns bottom link icon with drag handle
});

// Current solution for component selectors https://hello.atlassian.net/wiki/spaces/UAF/pages/1393355483/Compiled+Component+selectors#Proposed-solution---HTML-data-attribute-selectors (a WIP solution yet to be implement for better parent/child styling and will be documented)
const dragHandleContainerStyles = css({
	position: 'fixed',
	display: 'flex',
	flexDirection: 'column',
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
	justifyContent: 'center',
	borderRadius: '50%',
	pointerEvents: 'all',
	transform: 'translate(-50%, -50%)',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="timeline-drag-handler"]': {
			opacity: 1,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="timeline-data-dot"]': {
			opacity: 0,
		},
	},
});

const dragHandleTopContainerStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '1px', // aligns top dot with drag handle
	alignItems: 'flex-start',
});

const dragHandleBottomContainerStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-1px', // aligns bottom dot with drag handle
	alignItems: 'flex-end',
});

// Current solution for component selectors https://hello.atlassian.net/wiki/spaces/UAF/pages/1393355483/Compiled+Component+selectors#Proposed-solution---HTML-data-attribute-selectors (a WIP solution yet to be implement for better parent/child styling and will be documented)
const dragHandleContainerStylesOld = css({
	position: 'fixed',
	display: 'flex',
	flexDirection: 'column',
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '50%',
	pointerEvents: 'all',
	transform: 'translate(-50%, -50%)',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="timeline-drag-handler"]': {
			opacity: 1,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="timeline-data-dot"]': {
			opacity: 0,
		},
	},
});
