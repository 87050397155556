import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { CAN_ENROLL_IN_CONFIG_WIZARD_VIEW } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { ErrorBoundaryWithChunkErrorLogging } from '@atlassian/jira-onboarding-core/src/ui/error-boundary-with-chunk-error-logging/index.tsx';
import { sessionStorageProvider } from '@atlassian/jira-onboarding-core/src/utils/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from './types.tsx';
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyWelcomeModal = lazy(() =>
	import(/* webpackChunkName: "async-software-onboarding-modals-welcome-modal" */ './index').then(
		({ WelcomeModal }) => WelcomeModal,
	),
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyWelcomeModalWizard = lazy(() =>
	import(
		/* webpackChunkName: "async-software-onboarding-modals-welcome-modal-wizard" */ './wizard'
	).then(({ WelcomeModal }) => WelcomeModal),
);

export const AsyncWelcomeModal = ({ source }: Props) => {
	return (
		<UFOSegment name="async-onboarding-welcome-modal">
			<ErrorBoundaryWithChunkErrorLogging
				id="asyncWelcomeModalComponent"
				fallback="unmount"
				packageName="jiraSoftwareOnboardingModals"
				teamName="jlove-makkuro"
			>
				<Placeholder name="software-onboarding-modals-welcome-modal" fallback={<></>}>
					{
						// eslint-disable-next-line jira/ff/no-preconditioning
						fg('jira_onboarding_wizard_m1_feature_gate') &&
						['list', 'board'].includes(source) && // our new wizard only needs to render on list/board
						sessionStorageProvider.get(CAN_ENROLL_IN_CONFIG_WIZARD_VIEW) ? (
							<LazyWelcomeModalWizard source={source} />
						) : (
							<LazyWelcomeModal source={source} />
						)
					}
				</Placeholder>
			</ErrorBoundaryWithChunkErrorLogging>
		</UFOSegment>
	);
};
