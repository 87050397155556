import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';

import type {
	OnItemDrop,
	OnItemSelect,
	OnItemExpandChanged,
	OnCreateClick,
} from '@atlassian/timeline-table/common/types/callbacks';
import { DragAndDropLongTaskMetric } from '../../metrics/utils/long-task-drag-and-drop/index.tsx';
import { getToday, getSprints } from '../../state/configuration/selectors.tsx';
import {
	attemptRankIssue,
	updateAndPersistIssue,
	updateAndRankAndPersistIssue,
} from '../../state/entities/issues/actions.tsx';
import { getIsIssueFlaggedHash } from '../../state/entities/issues/selectors.tsx';
import { getTimelineMode } from '../../state/selectors/filters/index.tsx';
import { getSelectedIssueId } from '../../state/selectors/issues/index.tsx';
import { getListItemMenuOptions } from '../../state/selectors/list-item-menu-option/index.tsx';
import {
	getCanUserCreate,
	getCanUserEdit,
	getCanUserViewDetails,
} from '../../state/selectors/permission.tsx';
import {
	getItems,
	getItemHeight,
	getIsCreateSiblingEnabled,
	getIsCreateChildEnabled,
	getIsDragEnabled,
	getTableHeaderItems,
	getAriaReadOnly,
} from '../../state/selectors/table/index.tsx';
import { getTimelineDuration, getTimelineOrigin } from '../../state/selectors/timeline/index.tsx';
import { setListWidth } from '../../state/settings/actions.tsx';
import { getListWidth } from '../../state/settings/selectors.tsx';
import type { State } from '../../state/types.tsx';
import {
	setCreateItemAnchor,
	changeExpansionState,
	selectItems,
	selectMultipleItems,
	activateItem,
} from '../../state/ui/table/actions.tsx';
import {
	getExpandedItems,
	getSelectedItemIds,
	getCreateItemAnchor,
} from '../../state/ui/table/selectors.tsx';
import Table from '../common/table/index.tsx';

const onItemDrop: OnItemDrop = (id, { parentId, rankRequest }, analyticsEvent) => {
	if (parentId !== undefined && rankRequest !== undefined) {
		return updateAndRankAndPersistIssue(
			{ issueId: id, rankRequest, properties: { parentId } },
			analyticsEvent,
		);
	}

	if (rankRequest !== undefined) {
		return attemptRankIssue({ issueId: id, rankRequest }, analyticsEvent);
	}

	return updateAndPersistIssue(id, { parentId }, analyticsEvent);
};

const onItemExpandChanged: OnItemExpandChanged = (id, isExpanded) =>
	changeExpansionState({ id, isExpanded: !isExpanded });

const onCreateClick: OnCreateClick = (anchor) => setCreateItemAnchor(anchor);

const onItemSelect: OnItemSelect = (id, { level, withCmd, withShift }, analyticsEvent) =>
	selectItems({ id, level, withCmd, withShift }, analyticsEvent);

type OwnProps = {
	isServer: boolean;
};

const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const isSelectEnabled = getCanUserViewDetails(state);
	const canUserCreate = getCanUserCreate(state);
	const canUserEdit = getCanUserEdit(state);

	return {
		isHighlightToday: !ownProps.isServer,
		isMeatballsButtonEnabled: canUserEdit || canUserCreate,
		isCreateLastEnabled: canUserCreate,
		isCreateSiblingEnabled: getIsCreateSiblingEnabled(state),
		isCreateChildEnabled: getIsCreateChildEnabled(state),
		isDragEnabled: getIsDragEnabled(state),
		isSelectEnabled,
		isMultiSelectEnabled: fg('project_timeline_multi-select_and_checkboxes')
			? isSelectEnabled
			: isSelectEnabled || undefined,
		hasSprints: getSprints(state).length !== 0,
		items: getItems(state),
		expandedItems: getExpandedItems(state),
		createItemAnchor: getCreateItemAnchor(state),
		selectedItemIds: getSelectedItemIds(state),
		activeItemId: fg('project_timeline_multi-select_and_checkboxes')
			? getSelectedIssueId(state)
			: undefined,
		timelineMode: getTimelineMode(state),
		timelineDuration: getTimelineDuration(state),
		timelineOrigin: getTimelineOrigin(state),
		today: getToday(state),
		listWidth: getListWidth(state),
		itemHeight: getItemHeight(state),
		DndLongTaskMetric: DragAndDropLongTaskMetric,
		isVirtualiseEnabled: true,
		headerItems: getTableHeaderItems(state),
		ariaReadOnly: getAriaReadOnly(state),
		flaggedItemsHash: getIsIssueFlaggedHash(state),
		listItemMenuOptions: getListItemMenuOptions(state),
	};
};

export default connect(mapStateToProps, {
	onItemDrop,
	// Remove this with project_timeline_multi-select_and_checkboxes
	onItemSelect,
	onItemExpandChanged,
	onCreateClick,
	onSetListWidth: setListWidth,
	onItemActivate: activateItem,
	onItemMultiSelect: selectMultipleItems,
})(Table);
