import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';

import type { KeyDate } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/key-date.tsx';
import { RoadmapHighlightLines } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-timeline-overlay/highlight-lines/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-software-roadmap-timeline-table
import { useTimelineState as useTimelineStateOld } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';

import { useTimelineState as useTimelineStateNew } from '@atlassian/timeline-table/common/context/timeline';

export type Props = {
	highlightedKeyDates: string[];
	getVersionsForKeyDate: (today: number) => ReadonlyArray<KeyDate>;
};

const HighlightLines = ({ highlightedKeyDates, getVersionsForKeyDate }: Props) => {
	const useTimelineState = fg('switch_timeline_table_imports')
		? useTimelineStateNew
		: useTimelineStateOld;
	const [{ today }] = useTimelineState();
	const keyDates = getVersionsForKeyDate(today);

	return highlightedKeyDates.length ? (
		<RoadmapHighlightLines highlightedKeyDates={highlightedKeyDates} keyDates={keyDates} />
	) : null;
};

export { HighlightLines };
