import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { ROADMAP_PACKAGE_NAME } from '../../../constants.tsx';
import type { Props } from './view.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const JiraGlobalIssueCreateDialogTrigger = lazy(
	() => import(/* webpackChunkName: "async-standard-roadmap-global-issue-create" */ './view'),
);

const LazyJiraGlobalIssueCreateDialogTrigger = (props: Props) => {
	const { payloadGIC } = props;

	if (payloadGIC !== null) {
		return (
			<JSErrorBoundary
				id="async-standard-roadmap-global-issue-create"
				packageName={ROADMAP_PACKAGE_NAME}
				fallback="flag"
			>
				<Placeholder name="standard-roadmap-global-issue-create" fallback={null}>
					<JiraGlobalIssueCreateDialogTrigger {...props} />
				</Placeholder>
			</JSErrorBoundary>
		);
	}

	return null;
};

export default LazyJiraGlobalIssueCreateDialogTrigger;
